import * as React from "react";
import styled from 'styled-components';
import {Select, MenuItem, Button, CircularProgress, FormControl, Icon, IconButton, Input, InputAdornment, InputLabel} from "material-ui";
import JsonApi from "../common/JsonApi";
import SummerSchoolThemeProvider from "../common/SummerSchoolThemeProvider";
import { AREAS } from "../common/Constants";

const Paragraph = styled.div`
@media all and (max-width:767px){
display:none;
}
`;
const ParagraphTwo = styled.div`
display:none;
margin:0 -12px;

a{
text-decoration:none !important;
}

@media all and (max-width:767px){
display:block;
}
`;

export default class LocationListPage extends React.Component {
    state = {
        search: '',
        areas: [],
        selectedArea: AREAS.DEFAULT
    };

    componentDidMount() {
        JsonApi.jsonp('/api/areas/get-areas', {}, (areas) => {
            this.setState({
                ...this.state,
                areas: areas
            });
        });
    }

    onAreaChangeHandle = (event) => {
        this.setState({
            ...this.state,
            selectedArea: event.target.value
        })
    }

    render() {
        const isMobile = $(window).width() < 600;

        return <SummerSchoolThemeProvider>
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: isMobile ? 'stretch' : 'center',
                    marginBottom: '10px',
                    flexFlow: isMobile ? 'column' : 'row',
                }}>
                    <div style={{
                        fontSize: '30px',
                        color: '#3d3d3f',
                        fontWeight: '500',
                        flex: '1',
                        paddingBottom: isMobile ? '20px' : '0'
                    }}>
                        Kurssteder
                    </div>

                    <div style={{display: 'flex', alignItems: 'flex-end', gap: '20px'}}>
                        <div style={{flex: '1'}}>
                            <FormControl>
                                <InputLabel style={{fontSize: '1rem', fontWeight: '400'}}>
                                    Søk etter kurssted
                                </InputLabel>
                                <Input
                                    onChange={(event) => {
                                        this.setState({search: event.target.value});
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Icon style={{fontSize: '18px'}}>search</Icon>
                                        </InputAdornment>
                                    }
                                    inputProps={{
                                        style: {
                                            font: 'inherit',
                                            width: '100%',
                                            color: 'currentColor',
                                            border: '0',
                                            margin: '0',
                                            padding: '6px 0 7px',
                                            display: 'block',
                                            background: 'none',
                                            boxShadow: 'none',
                                            outline: 'none !important',
                                            outlineStyle: 'none !important'
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>

                        <Paragraph>
                            <Button href={'?map'} color={'primary'} raised
                                    style={{width: '200px', height: '40px', color: '#FFF'}}>
                                Se kurssteder i kart
                            </Button>
                        </Paragraph>
                        <ParagraphTwo>
                            <IconButton href={'?map'}>
                                <Icon>map</Icon>
                            </IconButton>
                        </ParagraphTwo>
                    </div>
                </div>
                <div style={{ marginBottom: '30px', display: 'flex', alignItems: 'flex-end' }}>
                    <span>Bydel:&nbsp;</span>
                    <FormControl style={{ margin: '5px 6px', width: '30%', flexGrow: isMobile ? 1 : 0 }}>
                        <Select value={this.state.selectedArea} onChange={this.onAreaChangeHandle}>
                            <MenuItem key={0} value={AREAS.DEFAULT}>Alle</MenuItem>
                            {this.state.areas && this.state.areas.map((area) => (
                                <MenuItem key={area.id} value={area.id}>
                                    {area.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <LocationListWidget
                    selectedArea={this.state.selectedArea}
                    search={this.state.search}
                />
            </div>
        </SummerSchoolThemeProvider>
    }
}

const Div = styled.div`
    display:flex;
    flex-wrap:wrap;
    align-items:stretch;
    
    > div {
        width:25%;
        
        @media all and (max-width: 767px) {
            width: 100%;        
        }
    }
`;

const fuzzyCompare = (text, search) => {
    if (search === '')
        return true;

    search = search.toLocaleLowerCase().split(" ");
    text = text.toLocaleLowerCase().split(" ");


    for (let i = 0; i < search.length; i++) {
        if (search[i] === '')
            continue;

        let found = false;

        for (let j = 0; j < text.length; j++) {
            if (text[j] === '')
                continue;

            if (text[j].substr(0, search[i].length) === search[i])
                found = true;
        }

        if (!found)
            return false;
    }

    return true;
};

class LocationListWidget extends React.Component {
    state = {locations: null};

    render() {
        const selectedArea = this.props.selectedArea

        if (!this.state.locations) {
            return <div style={{
                display: 'flex',
                width: '100%',
                height: '400px',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress size={50}/>
            </div>;
        }

        const locationWidgets = [];

        this.state.locations.forEach((location) => {
            if (!fuzzyCompare(location.name, this.props.search))
                return;

            if (selectedArea !== AREAS.DEFAULT && location.areaId !== selectedArea) {
                return;
            }

            locationWidgets.push(<LocationWidget location={location} key={location.id}/>);
        });

        return <div style={{margin: '0 -10px'}}>
            <Div style={{display: 'flex'}}>
                {locationWidgets}
            </Div>
        </div>;
    }

    componentDidMount() {
        JsonApi.jsonp('/api/location-list/get-locations', {}, (locations) => {
            this.setState({locations});
        });
    }
}

const A = styled.a`

    img {
        transition: all 0.3s;
    }
    
    &:hover {
        img {   
            transform: scale(1.1);
        }
    }
        
    .image-overlay.overlay-type-extern {
        display:none !important;
    }

`;

class LocationWidget extends React.Component {
    render() {
        const location = this.props.location;
        return <div style={{display: 'flex', alignItems: 'stretch', padding: '10px'}}>
            <A href={'?locationId=' + location.id} style={{border: '1px solid #a4a4a4', flex: '1'}}>
                {location.imageUrl ?
                    <div style={{width: '100%', height: '150px', overflow: 'hidden'}}>
                        <img
                            src={location.imageUrl}
                            style={{display: 'block', width: '100%', height: '150px', objectFit: 'cover',}}
                        />
                    </div>
                    : null}
                <div style={{padding: '20px', fontSize: '15px', color: '#3d3d3f', lineHeight: '1.3'}}>
                    <div style={{fontWeight: '600', marginBottom: '5px'}}>{location.name}</div>
                    <div>{location.address}</div>
                </div>
            </A>
        </div>
    }
}