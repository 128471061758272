import * as React from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "material-ui";

export default class JsonApi {
    static endpoint = '';

    static post(url, data, success) {
        $.ajax({
            type: 'POST',
            url: JsonApi.endpoint + url,
            data: data,
            success: (res, textStatus, jqXHR) => {
                if (jqXHR.responseText === res) {
                    JsonApi.openError(jqXHR);
                    return;
                }

                if (success)
                    success(res);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                JsonApi.openError(jqXHR);
            },
            statusCode: {
                401: function () {
                    const id = 'C' + Math.round(Math.random() * 100000000);
                    $('body').append('<div id="' + id + '"></div>');
                    ReactDOM.render(
                        <Dialog open>
                            <DialogTitle>Krever autentisering</DialogTitle>
                            <DialogContent>
                                <DialogContentText>Sesjonstiden har utgått. Du må logge inn på nytt.</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button color={'primary'} href={location.href}>Logg inn på nytt</Button>
                            </DialogActions>
                        </Dialog>,
                        document.getElementById(id),
                    );
                },
                403: function () {
                    const id = 'C' + Math.round(Math.random() * 100000000);
                    $('body').append('<div id="' + id + '"></div>');
                    ReactDOM.render(
                        <Dialog open onClose={() => {
                            ReactDOM.unmountComponentAtNode(document.getElementById(id));
                            $('#'+id).remove();
                        }}>
                            <DialogTitle>Mangler tilgang</DialogTitle>
                            <DialogContent>
                                <DialogContentText>Du mangler tilgang til dette innholdet. Prøv å start
                                    påmeldingsprossesen på nytt igjen, eller prøv igjen senere.</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button color={'primary'} onClick={() => {
                                    ReactDOM.unmountComponentAtNode(document.getElementById(id));
                                    $('#'+id).remove();
                                }}>Lukk</Button>
                            </DialogActions>
                        </Dialog>,
                        document.getElementById(id),
                    );
                }
            },
        });
    }

    static jsonp(url, data, success) {
        $.ajax({
            dataType: "jsonp",
            url: JsonApi.endpoint + url,
            data: data,
            success: (res, textStatus, jqXHR) => {
                if (jqXHR.responseText === res) {
                    JsonApi.openError(jqXHR);
                    return;
                }

                if (success)
                    success(res);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                JsonApi.openError(jqXHR);
            },
        });
    }

    static openError(jqXHR) {

        // const snackId = SnackbarWrapper.pushSnackbar(
        //     <Snackbar
        //         open={true}
        //         autoHideDuration={12000}
        //         onRequestClose={this.handleRequestClose}
        //         transition={<Slide direction={'up'}/>}
        //         message={' Server error: invalid response'}
        //         action={
        //             <Button color="accent" dense onClick={() => {
        //                 SnackbarWrapper.unloadSnackbar(snackId);
        //                 const id = SnackbarWrapper.pushSnackbar(
        //                     <Dialog open={true} onRequestClose={() => SnackbarWrapper.unloadSnackbar(id)}>
        //                         <DialogTitle>{"Malformed server response"}</DialogTitle>
        //                         <DialogContent>
        //                             <DialogContentText>
        //                                 {jqXHR.responseText}
        //                             </DialogContentText>
        //                         </DialogContent>
        //                         <DialogActions>
        //                             <Button onClick={() => SnackbarWrapper.unloadSnackbar(id)}>
        //                                 Close
        //                             </Button>
        //                             <Button onClick={() => {
        //                                 parent.location.reload();
        //                             }} color="primary" autoFocus>
        //                                 Restart
        //                             </Button>
        //                         </DialogActions>
        //                     </Dialog>
        //                 )
        //             }}>
        //                 See response
        //             </Button>
        //         }
        //     />
        // );
    }

    static setEndpoint(endpoint) {
        JsonApi.endpoint = endpoint;
    }
}