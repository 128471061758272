export const AREAS = {
    DEFAULT: 0
}

export const BREAKPOINTS = {
    TABLET: 720
}

export const COLORS = {
    GREEN_DARK: '#034B45',
    BEIGE: '#F8F0DD',
    BLUE_TEAL: '#006275',
    VERMILION_MEDIUM: '#E75830',
    GRAY_DARK: '#2C2C2C',
    GRAY: '#AEAEAE',
    YELLOW: '#F9C66B',
    RED: '#E55858',
    WHITE: '#FFFFFF',
    BLACK: '#000000'
}

export const FONTS = {
    OSLO_SANS: '"OsloSans", sans-serif'
}

export const THEME = {
    USE_V2: true,
    PRIMARY_COLOR: COLORS.GREEN_DARK,
    SECONDARY_COLOR: COLORS.BEIGE,
    TEXT_COLOR: COLORS.GRAY_DARK,
    TEXT_COLOR_WHITE: COLORS.WHITE,
    BUTTON_BACKGROUND_WARNING: COLORS.YELLOW,
    BUTTON_BACKGROUND_DANGER: COLORS.RED,
    BUTTON_BACKGROUND_DISABLE: COLORS.GRAY,
    FONT: FONTS.OSLO_SANS,
    DIALOG_TEXT_COLOR: COLORS.BLACK
}

export const KEYCODE = {
    ENTER: 13,
    SPACE_BAR: 32
}