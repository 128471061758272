import * as React from "react";
import styled from "styled-components";
import CourseCatalogFilters from "./CourseCatalogFilters";
import {LocationMatrixButton, LocationName} from "./MatrixCourse";
import ActivityApplyAction from "./ActivityApplyAction";
import ContentFormatted from "../../common/ContentFormatted";
import { AREAS, THEME } from "../../common/Constants";

const Th = styled.th`
    padding: 10px;
    font-size:15px;
    border:none !important;
    text-transform:none;
    font-size:15px;
    text-align:left;
`;
const Td = styled.td`
    padding: 10px;
    font-size:15px;
    vertical-align: middle;
    border:none !important;
    border-top: 1px solid #e6e6e6 !important;
    font-size:15px;
    text-align:left;

    &:last-child {
        text-align:center;
    }
`;


export default class ListCourse extends React.Component {
    render() {
        const course = this.props.course;

        return <div style={{padding: '20px 30px'}}>
            <ContentFormatted html={course.description}/>
            <LocationMatrixButton {...this.props} />
            <table style={{margin: '0'}}>
                <thead>
                <tr style={{background: 'white'}}>
                    {course.showActivityNameColumn ? <Th>Kursnavn</Th> : null}
                    <Th>Kurssted</Th>
                    <Th>Dato</Th>
                    <td></td>
                </tr>
                </thead>
                <tbody>
                {Object.keys(course.list).map(activityId => {
                    const activity = course.list[activityId];
                    const location = activity.location;

                    if (!this.getFilters().isWeekShown(activity.week)) {
                        return null;
                    }

                    if (
                        this.props.showAvailableCourse && (
                            activity.bookingDeadlineExpired
                            || activity.ended
                            || (activity.isWaitingListEnabled && activity.numberOfVacantSlots <= 0)
                        )
                    ) {
                        return null;
                    }

                    if ((this.props.selectedArea !== AREAS.DEFAULT && this.props.selectedArea !== activity.location.areaId)) {
                        return null;
                    }

                    return <tr key={activityId} style={{background: 'white', color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#3f3f3d'}}>
                        {course.showActivityNameColumn ? <Td>{activity.name}</Td> : null}
                        <Td><LocationName location={location} {...this.props}/></Td>
                        <Td>{activity.dateFormatted}</Td>
                        <Td>
                            <ActivityApplyAction
                                activity={activity}
                                {...this.props}
                            />
                        </Td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>;
    }

    getFilters(): CourseCatalogFilters {
        return this.props.catalogFilters;
    }

    componentDidMount() {
        console.log("Aktivitet");
    }
}