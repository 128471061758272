import { KEYCODE } from "../common/Constants";

export function isKeyPress(keyCode, event) {
    const events = ['keyup', 'keydown', 'keypress'];

    if (events.includes(event.type) && (typeof keyCode !== 'number' || event.keyCode !== keyCode))
        return false;

    return true;
}

export function enterKeyHandle(event, unFocusToElement = false) {
    if (!isKeyPress(KEYCODE.ENTER, event)) return;

    const element = event.target;

    if (!element) {
        return;
    }

    element.click();

    if (unFocusToElement) {
        element.blur();
    }
}

export function attachEnterKeyEventHandle(selector = '[enter-key-handle]', tabindex = 0) {
    $(selector).each(function(index, element) {
        $(element).attr("tabindex", tabindex)

        $(element).off('keyup').on('keyup', function (event) {
            enterKeyHandle(event, true);
        });
    });
}