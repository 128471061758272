import * as React from "react";
import {FormControl, FormLabel, FormHelperText, Input, InputLabel, Select} from "material-ui";
import { FormGroup } from "material-ui";

export default class BirthdateField extends React.Component {
    state = {day: null, month: null, year: null};

    days = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
    ];

    months = [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
    ];
    monthNames = {
        '01': 'Januar',
        '02': 'Februar',
        '03': 'Mars',
        '04': 'April',
        '05': 'Mai',
        '06': 'Juni',
        '07': 'Juli',
        '08': 'August',
        '09': 'September',
        '10': 'Oktober',
        '11': 'November',
        '12': 'Desember',
    };

    render() {
        if (this.state.day === null)
            this.state.day = this.props.defaultValue ? this.props.defaultValue.substr(8, 2) : '';
        if (this.state.month === null)
            this.state.month = this.props.defaultValue ? this.props.defaultValue.substr(5, 2) : '';
        if (this.state.year === null)
            this.state.year = this.props.defaultValue ? this.props.defaultValue.substr(0, 4) : '';

        return <div style={{display: 'flex', flexDirection: 'column', marginTop: '16px', marginBottom: '8px'}}>
            {this.props.showFieldLabel && <div style={{marginTop: '16px'}}><FormLabel component="legend" style={{color: '#57585a'}}>Fødselsdato</FormLabel></div>}
            <FormGroup style={{flexDirection: 'row'}}>
                <div style={{flex: '1', paddingRight: '10px'}}>
                    <FormControl fullWidth disabled={this.props.disabled} error={this.props.error}>
                        <InputLabel>Dag</InputLabel>
                        <Select
                            native
                            value={this.state.day}
                            onChange={(event) => {
                                this.setState({day: event.target.value}, () => {
                                    this.props.onChange(this.getValue());
                                });
                            }}
                            input={<Input/>}
                        >
                            <option value=""/>
                            {this.days.map(dayId => {
                                return <option value={dayId} key={dayId}>{Number(dayId)}</option>;
                            })}
                        </Select>
                        {this.props.helperText ? <FormHelperText error>{this.props.helperText}</FormHelperText> : null}
                    </FormControl>
                </div>
                <div style={{flex: '1', paddingLeft: '10px', paddingRight: '10px'}}>
                    <FormControl fullWidth disabled={this.props.disabled} error={this.props.error}>
                        <InputLabel>Måned</InputLabel>
                        <Select
                            native
                            value={this.state.month}
                            onChange={(event) => {
                                this.setState({month: event.target.value}, () => {
                                    this.props.onChange(this.getValue());
                                });
                            }}
                            input={<Input/>}
                        >
                            <option value=""/>
                            {this.months.map(monthId => {
                                return <option value={monthId} key={monthId}>{this.monthNames[monthId]}</option>;
                            })}
                        </Select>
                        {this.props.helperText ? <FormHelperText error>{this.props.helperText}</FormHelperText> : null}
                    </FormControl>
                </div>
                <div style={{flex: '1', paddingLeft: '10px'}}>
                    <FormControl fullWidth disabled={this.props.disabled} error={this.props.error}>
                        <InputLabel>År</InputLabel>
                        <Select
                            native
                            value={this.state.year}
                            onChange={(event) => {
                                this.setState({year: event.target.value}, () => {
                                    this.props.onChange(this.getValue());
                                });
                            }}
                            input={<Input/>}
                        >
                            <option value=""/>
                            {this.getYears().map(year => {
                                return <option value={year} key={year}>{year}</option>;
                            })}
                        </Select>
                        {this.props.helperText ? <FormHelperText error>{this.props.helperText}</FormHelperText> : null}
                    </FormControl>
                </div>
            </FormGroup>
        </div>
    }

    getYears() {
        let years = [];

        for (let i = (new Date()).getFullYear(); i > 1960; i--) {
            years.push(Number(i).toString());
        }

        return years;
    }

    getValue() {
        return this.state.day && this.state.month && this.state.year
            ? this.state.year + '-' + this.state.month + '-' + this.state.day
            : null;
    }
}