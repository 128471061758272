import * as React from "react";
import {createMuiTheme, MuiThemeProvider} from 'material-ui/styles';
import orange from "material-ui/colors/orange";
import blueGrey from "material-ui/colors/blueGrey";
import red from "material-ui/colors/red";
import { COLORS, THEME } from "./Constants";


export default class SummerSchoolThemeProvider extends React.Component {
    render() {
        // const theme = createMuiTheme({
        //     palette: {
        //         primary: {
        //             light: '#00a6c4',
        //             main: '#006275',
        //             dark: '#00465b',
        //         },
        //         secondary: {
        //             light: '#e75830',
        //             main: '#e75830',
        //             dark: '#e75830',
        //         },
        //     },
        // });


        const theme = createMuiTheme({
            palette: {
                primary: {
                    ...blueGrey,
                    500: COLORS.BLUE_TEAL
                },
                secondary: {
                    ...orange,
                    A200: COLORS.VERMILION_MEDIUM
                },
                error: red,
            },
        });

        const themeV2 = createMuiTheme({
            overrides: {
                MuiInput: {
                    root: {
                        color: THEME.TEXT_COLOR
                    },
                    inkbar: {
                        '&:after': {
                            backgroundColor: THEME.PRIMARY_COLOR
                        }
                    }
                },
                MuiFormLabel: {
                    root: {
                        color: THEME.TEXT_COLOR
                    },
                    focused: {
                        color: THEME.PRIMARY_COLOR
                    }
                },
                MuiDialogContentText: {
                    root: {
                        color: THEME.DIALOG_TEXT_COLOR
                    }
                }
                // MuiFormHelperText: {
                //     root: {
                //         color: COLORS.RED
                //     }
                // }
            },
            typography: {
                fontFamily: THEME.FONT
            },
            palette: {
                text: {
                    primary: THEME.TEXT_COLOR
                },
                primary: {
                    ...blueGrey,
                    500: THEME.PRIMARY_COLOR
                },
                secondary: {
                    ...orange,
                    A200: THEME.SECONDARY_COLOR
                },
                error: red,
            },
        })

        const selectedTheme = THEME.USE_V2 ? themeV2 : theme;

        return <MuiThemeProvider theme={selectedTheme}>
            {this.props.children}
        </MuiThemeProvider>;
    }
}