"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var regExp = /([A-Z])/g;
var replace = function replace(str) {
  return "-" + str.toLowerCase();
};

exports["default"] = function (str) {
  return str.replace(regExp, replace);
};