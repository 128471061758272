import * as React from "react";
import JsonApi from "../../common/JsonApi";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "material-ui";
import {Courses, MobileShoppingCartButton, TabletShoppingCartIcon} from "./CourseCatalogWrapper";
import {LocationMatrixInfowindow} from "./MatrixCourse";

export default class ShoppingCart extends React.Component {
    static data;
    static instance;


    render() {
        return <div style={{padding: '20px'}}>
            <div className="Kat-Title">
                <span>3</span>
                <h2>{ShoppingCart.data ? ShoppingCart.data.cartName : ''}</h2>
            </div>

            {ShoppingCart.data
                ? <div>
                    {ShoppingCart.data.description ?
                        <div className={'content'} style={{
                            marginTop: '-8px',
                            marginBottom: '16px'
                        }} dangerouslySetInnerHTML={{__html: ShoppingCart.data.description}}/> : null}

                    {ShoppingCart.data.lines.map(line => <ShoppingCart_Line
                        key={line.participant ? line.participant._id : line.activity._id}
                        line={line}
                        {...this.props}
                    />)}


                    {this.props.backButton ? <div style={{marginTop: '20px'}}>
                        <Button raised onClick={this.props.backButton}
                                style={{
                                    width: '100%',
                                    color: 'black'
                                }}>{ShoppingCart.data.canAddMore ? 'Legg til flere kurs' : 'Tilbake'}</Button>
                    </div> : null}
                    {ShoppingCart.data.nextStep ? <div style={{marginTop: '10px'}}>
                        <Button raised color={'primary'} onClick={() => {
                            BookingProcessEscapeMessage.setEscapeWarningEnabled(false);
                            location.href = ShoppingCart.data.nextStep;
                        }}
                                style={{width: '100%', color: 'white'}}>Gå videre</Button>
                    </div> : null}

                </div>
                : null}
        </div>
    }

    componentWillMount() {
        ShoppingCart.instance = this;
    }

    static async updateData(childId, enrollmentGroupId) {
        return new Promise(solve => {
            JsonApi.post('/api/course-catalog/get-shopping-cart', {
                childId: childId,
                enrollmentGroupId: enrollmentGroupId,
                includeDeniedType: true
            }, (res) => {
                ShoppingCart.data = res;
                solve();

                if (Courses.instance)
                    Courses.instance.setState({});

                if (MobileShoppingCartButton.instance)
                    MobileShoppingCartButton.instance.forceUpdate();

                if (TabletShoppingCartIcon.instance)
                    TabletShoppingCartIcon.instance.forceUpdate();

                if (ShoppingCart.instance)
                    ShoppingCart.instance.forceUpdate();

                LocationMatrixInfowindow.instances.forEach(instance => {
                    instance.forceUpdate();
                })
            });
        })
    }

    static getData() {
        return ShoppingCart.data;
    }

    static updateWidget() {
        ShoppingCart.instance.forceUpdate();
    }

    static isActivityInCart(activityId) {
        return ShoppingCart.data && ShoppingCart.data.activityIdsInCart.indexOf(activityId) !== -1;
    }

    static isEnrolledToActivity(activityId) {
        return ShoppingCart.data && ShoppingCart.data.activityIdsEnrolledTo.indexOf(activityId) !== -1;
    }
}

export class BookingProcessEscapeMessage extends React.Component {
    static escapeWarningEnabled = true;
    static instance;
    state = {modal: null};

    render() {
        return <div>
            {this.state.modal}
        </div>;
    }

    componentWillMount() {
        BookingProcessEscapeMessage.instance = this;
    }

    componentDidMount() {
        let _this = this;
        $('.SS-Header-Widget a, .SS-ChildSelector a').click(function (e) {
            const $this = $(this);

            if ((ShoppingCart.data && ShoppingCart.data.nextStep) || _this.props.force) {
                e.preventDefault();
                e.stopPropagation();

                _this.setState({
                    modal: <Dialog open onClose={() => _this.setState({modal: null})}>
                        <DialogTitle>Ønsker du å avbryte?</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Du er i ferd med å avbryte. Kurset blir slettet. Er du sikker på at du vil dette?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => _this.setState({modal: null})}>Avbryt</Button>
                            <Button onClick={() => {
                                BookingProcessEscapeMessage.setEscapeWarningEnabled(false);
                                JsonApi.post('/api/shopping-cart/clear', {
                                    childId: _this.props.child.id,
                                    enrollmentGroupId: _this.props.enrollmentGroup._id
                                }, () => {
                                    location.href = $this.attr('href');
                                });
                            }} color={'primary'}>Bekreft</Button>
                        </DialogActions>
                    </Dialog>
                });
            }
        });

        window.addEventListener("beforeunload", function (e) {
            if (ShoppingCart.data.nextStep && BookingProcessEscapeMessage.escapeWarningEnabled) {
                const confirmationMessage = 'Du er i ferd med å avbryte. Kurset blir slettet. Er du sikker på at du vil dette?';

                (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
            }
        });
    }

    static setEscapeWarningEnabled(enabled) {
        BookingProcessEscapeMessage.escapeWarningEnabled = enabled;
    }
}

class ShoppingCart_Line extends React.Component {
    state = {modal: null};

    render() {
        const line = this.props.line;
        return <div style={{
            display: 'flex',
            padding: '13px 20px',
            marginBottom: '10px',
            fontSize: '15px',
            border: '1px solid #a4a4a4'
        }}>
            <div style={{flex: 1}}>
                <div style={{fontWeight: '700', lineHeight: '1.2'}}>
                    {line.activity.name}
                    {line.tags.indexOf('new') !== -1 ? <div style={{
                        display: 'inline-block',
                        textTransform: 'uppercase',
                        borderRadius: '30px',
                        background: '#ffd500',
                        fontSize: '12px',
                        fontWeight: '700',
                        color: '#3d3d3f',
                        padding: '0 6px',
                        marginLeft: '12px'
                    }}>Ny</div> : null}
                </div>
                <div style={{fontSize: '13px', lineHeight: '1'}}>{line.activity.dateFormatted}</div>
                <div style={{fontSize: '13px', lineHeight: '1'}}>{line.location ? line.location.name : null}</div>
            </div>
            <a onClick={() => this.remove()} onKeyUp={(event) => enterKeyHandle(event)} tabIndex={0}>
                <i className={'fa fa-times-circle'} style={{color: '#57585a', fontSize: '25px'}}/>
            </a>
            {this.state.modal}
        </div>;
    }

    remove() {
        const line = this.props.line;
        if (line.participant) {
            this.setState({
                modal: <Dialog open onClose={() => this.setState({modal: null})}>
                    <DialogTitle>Er du sikker på at du vil slette kurset?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Kurset <i>{line.activity.name}</i> vil bli slettet fra <i>{this.props.child.name}</i> sin
                            ønskeliste. Du vil få en bekreftelse på e-post.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({modal: null})}>Avbryt</Button>
                        <Button onClick={() => this.removeProcess()} color={'primary'} autoFocus>Bekreft</Button>
                    </DialogActions>
                </Dialog>
            });
        } else {
            this.removeProcess();
        }
    }

    removeProcess() {
        const line = this.props.line;

        JsonApi.post('/api/shopping-cart/remove-from-cart', {
            enrollmentGroupId: this.props.enrollmentGroup._id,
            childId: this.props.child.id,
            activityId: line.activity._id
        }, async (res) => {
            if (res === true) {
                await ShoppingCart.updateData(
                    this.props.child.id,
                    this.props.enrollmentGroup._id
                );
                ShoppingCart.updateWidget();
            } else {
                this.setState({
                    modal: <Dialog open onClose={() => this.setState({modal: null})}>
                        <DialogTitle>Feil</DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{maxWidth: '300px'}}>
                                Avmeldingsfristen er utgått, eller du har ikke tillatelse til å redigere dette.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({modal: null})}>Lukk</Button>
                        </DialogActions>
                    </Dialog>
                });
            }
        })
    }
}