import * as React from "react";
import $ from 'jquery';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "material-ui";
import JsonApi from "../common/JsonApi";
import { THEME } from "../common/Constants";

function setAuthenticateDialogWrapperAriaHidden(ariaHiddenValue = false) {
    const authenticateDialog = $('#AuthenticateDialog');
    const authenticateDialogWrapper = authenticateDialog.parent();

    authenticateDialogWrapper.length > 0 && authenticateDialogWrapper.attr('aria-hidden', ariaHiddenValue);
}

export default class AuthenticationModal extends React.Component {
    state = {view: null};

    render() {
        const options = {
            onSetView: view => this.setState({view}),
            ...this.props
        };

        if (this.state.view === null && this.props.view === 'email-password')
            this.state.view = <EnterEmailAndPasswordView {...options}/>

        if (this.state.view)
            return this.state.view;

        return <SelectAuthenticationMethod {...options}/>;

    }
}

class SelectAuthenticationMethod extends React.Component {
    state = {open: true};

    render() {
        return <Dialog className="ss-v2" id={'AuthenticateDialog'} open={this.state.open} onClose={() => {
            if (this.props.dismissible !== false)
                this.setState({open: false})
        }}>
            <DialogTitle style={{color: '#222', fontSize: '20px', fontWeight: '500'}} id={'AuthenticateTitle'}>
                Logg inn for påmelding
            </DialogTitle>
            <DialogContent style={{maxWidth: '350px'}}>
                <div>
                    <div style={{color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#767676', fontSize: '16px', lineHeight: '24px', marginBottom: '30px'}}>Du blir
                        nå sendt til
                        Sommerskolens påmeldingsportal. Informasjon om foresatte og elever hentes fra
                        skoleplattformen Oslo.
                    </div>
                    <div style={{color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#767676', fontSize: '16px', lineHeight: '24px', marginBottom: '14px'}}>
                        Velg alternativet som passer deg:
                    </div>
                </div>

                <Button raised href={JsonApi.endpoint + '/?action=idporten'} color={'primary'}
                        style={{width: '100%', marginBottom: '15px'}}>
                    Foresatt
                </Button>

                <Button raised href={JsonApi.endpoint + '/?action=feide'} color={'primary'}
                        style={{width: '100%', marginBottom: '15px', textAlign: 'center'}}>
                    Elev i osloskolen eller skolepåmelder
                </Button>

                <Button raised color={'primary'}
                        style={{width: '100%', backgroundColor: THEME.USE_V2 ? THEME.SECONDARY_COLOR : '#9ebbc1', color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#000', marginBottom: '15px'}}
                        href={this.props.bookingLink}
                >
                    Ansatt i sommerskolen
                </Button>

                <Button dense
                        style={{color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#aaa'}}
                        href={this.props.emailPasswordLink ? JsonApi.endpoint + '/?email-password' : undefined}
                        onClick={() => {
                            if (!this.props.emailPasswordLink)
                                this.props.onSetView(<EnterEmailAndPasswordView {...this.props}/>);
                        }}>
                    Intern
                </Button>
            </DialogContent>
            <DialogActions>
                {this.props.dismissible !== false
                    ? <Button color={'primary'} onClick={() => {
                        this.setState({open: false})
                    }}>Lukk</Button>
                    : null}
            </DialogActions>
        </Dialog>;
    }

    componentDidMount() {
        $('#AuthenticateTitle h2')
            .css('font-size', '20px')
            .css('color', THEME.USE_V2 ? THEME.TEXT_COLOR : '#222')
            .css('font-weight', 'medium');

        setAuthenticateDialogWrapperAriaHidden();
    }
}

class EnterEmailAndPasswordView extends React.Component {
    state = {open: true, failed: false};

    componentDidMount() {
        setAuthenticateDialogWrapperAriaHidden();
    }

    render() {
        return <Dialog id={'AuthenticateDialog'} open={this.state.open} onClose={() => {
            if (this.props.dismissible !== false)
                this.setState({open: false});
        }}>
            <form onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();

                this.submit();
            }}>
                <DialogTitle style={{color: '#222', fontSize: '20px', fontWeight: 'medium'}} id={'AuthenticateTitle'}>
                    Logg inn for påmelding
                </DialogTitle>
                <DialogContent style={{maxWidth: '350px'}}>
                    <TextField
                        id='email'
                        InputLabelProps={{ htmlFor: 'email' }}
                        label={'E-post adresse'}
                        type={'email'}
                        name={'email'}
                        error={this.state.failed}
                        fullWidth
                        autoFocus
                        onChange={event => {
                            this.setState({email: event.target.value});
                        }}
                    />
                    <div style={{marginTop: '16px'}}>
                        <TextField
                            id='password'
                            InputLabelProps={{ htmlFor: 'password' }}
                            label={'Passord'}
                            type={'password'}
                            name={'password'}
                            error={this.state.failed}
                            fullWidth
                            onChange={event => {
                                this.setState({password: event.target.value});
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.onSetView(<SelectAuthenticationMethod {...this.props}/>);
                    }}>Tilbake</Button>
                    <Button color={'primary'} type={'submit'}>Logg inn</Button>
                </DialogActions>
            </form>
        </Dialog>;
    }

    submit() {
        JsonApi.post('/api/user-session/authenticate-email-password', {
            email: this.state.email,
            password: this.state.password,
        }, (res) => {
            if (res.success) {
                location.reload();
            } else if (res.error) {
                this.setState({failed: true});
            }
        });
    }
}