import * as React from "react";
import {Manager, Popper, Target} from "react-popper";
import $ from 'jquery';
import styled from 'styled-components';
import scrollToElement from "scroll-to-element";
import {
    Badge,
    Button,
    CircularProgress,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grow,
    Icon,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuList,
    Paper
} from "material-ui";
import SummerSchoolThemeProvider from "../../common/SummerSchoolThemeProvider";
import CourseCatalogFilters from "./CourseCatalogFilters";
import ListCourse from "./ListCourse";
import MatrixCourse from "./MatrixCourse";
import ShoppingCart, {BookingProcessEscapeMessage} from "./ShoppingCart";
import JsonApi from "../../common/JsonApi";
import UserSession from "../../common/UserSession";
import * as ReactDOM from "react-dom";
import ContentFormatted from "../../common/ContentFormatted";
import { AREAS, COLORS, THEME } from "../../common/Constants";

export default class CourseCatalogWrapper extends React.Component {
    static instance: CourseCatalogWrapper;
    state = {
        catalog: null,
        child: null,
        enrollmentGroup: null,
        catalogFilters: null,
        showAvailableCourse: false,
        selectedArea: AREAS.DEFAULT,
        filterByGrade: false
    };

    showAvailableCourseChangeHandle = () => {
        this.setState((oldState) => {
            return {
                ...this.state,
                showAvailableCourse: !oldState.showAvailableCourse
            }
        })
    }

    areaChangeHandle = (event) => {
        this.setState(() => {
            return {
                ...this.state,
                selectedArea: event.target.value
            }
        })
    }

    render() {
        if (this.state.catalog === null)
            return <SummerSchoolThemeProvider>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '400px',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <CircularProgress
                        size={40}
                        color={THEME.USE_V2 ? 'primary' : 'accent'}
                    />
                </div>
            </SummerSchoolThemeProvider>;

        return <SummerSchoolThemeProvider>
            <div>
                <CourseCatalogDescription description={this.state.description}/>
                <CourseCatalogFilters
                    child={this.state.child}
                    enrollmentGroup={this.state.enrollmentGroup}
                    defaultYearGrade={this.state.defaultYearGrade}
                    catalog={this.state.catalog}
                    isSuperAdmin={this.state.isSuperAdmin}
                    isAdministrator={this.state.isAdministrator}
                    isAdmin={this.state.isAdmin}
                    areas={this.state.areas}
                    selectedArea={this.state.selectedArea}
                    onAreaChange={this.areaChangeHandle}
                    showAvailableCourse={this.state.showAvailableCourse}
                    onShowAvailableCourseChange={this.showAvailableCourseChangeHandle}
                    ref={catalogFilters => {
                        if (this.state.catalogFilters === null)
                            this.setState({catalogFilters: catalogFilters});
                    }}
                    filtersUpdated={() => {
                        this.forceUpdate();
                    }}
                />
                <Courses {...this.props} {...this.state}/>
                {this.props.childId ? <ShoppingCartWrapper {...this.props} {...this.state}/> : null}
            </div>
        </SummerSchoolThemeProvider>;
    }

    componentWillMount() {
        CourseCatalogWrapper.instance = this;
    }

    async componentDidMount() {
        JsonApi.jsonp('/api/course-catalog/get-course-catalog', {
            enrollmentGroupId: this.props.enrollmentGroupId,
            childId: this.props.childId,
        }, (response) => {
            this.setState({
                catalog: response.catalog,
                isSuperAdmin: response.isSuperAdmin,
                isAdministrator: response.isAdministrator,
                isAdmin: response.isAdmin,
                description: response.description,
                child: response.child,
                enrollmentGroup: response.enrollmentGroup,
                defaultYearGrade: response.defaultYearGrade,
                areas: response.areas
            });
        });
    }

    getFilters(): CourseCatalogFilters {
        return this.catalogFilters;
    }
}

class CourseCatalogDescription extends React.Component {
    render() {
        return <ContentFormatted html={this.props.description}/>;
    }
}

export class Courses extends React.Component {
    static instance: Courses;
    state = {courseId: null};

    render() {
        const catalog = this.props.catalog;
        const child = this.props.child;
        const isSuperAdmin = this.props.isSuperAdmin;
        const isAdministrator = this.props.isAdministrator;
        const isAdmin = this.props.isAdmin;
        const enrollmentGroup = this.props.enrollmentGroup;
        const showAvailableCourse = this.props.showAvailableCourse;
        const selectedArea = this.props.selectedArea;

        if (!catalog || !this.getFilters())
            return <div/>;

        let foundCourses = false;
        let courses = catalog.courses.map(course => {
            if (!this.getFilters().isCourseShown(course, this.props.filterByGrade ? this.props.filterByGrade : false))
                return;

            const isCourseHaveAvailableActivity = !!Object.values(course.list)
            .find(activity => !activity.ended && !activity.bookingDeadlineExpired && !(activity.isWaitingListEnabled && activity.numberOfVacantSlots <= 0));

            if (this.props.showAvailableCourse && !isCourseHaveAvailableActivity) {
                return;
            }

            if (AREAS.DEFAULT !== selectedArea && !Object.values(course.list).find((activity => activity.location.areaId === selectedArea))) {
                return;
            }

            foundCourses = true;

            return <Course
                key={course._id}
                id={'CourseCatalog-Course-' + course._id}
                child={child}
                isAdmin={isAdmin}
                isSuperAdmin={isSuperAdmin}
                showAvailableCourse={showAvailableCourse}
                selectedArea={selectedArea}
                isAdministrator={isAdministrator}
                course={course}
                catalogFilters={this.props.catalogFilters}
                enrollmentGroup={enrollmentGroup}
                catalog={catalog}
                open={this.state.courseId === course._id}
                onToggleClick={() => {
                    if (this.state.courseId === course._id)
                        this.setState({courseId: null});
                    else
                        this.setState({courseId: course._id});
                }}
            />;
        });

        return <div>
            {courses}
            {!foundCourses
                ? <div style={{
                    background: THEME.USE_V2 ? THEME.SECONDARY_COLOR : 'rgba(231,88,48,0.1)',
                    height: '120px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '18px',
                    color: THEME.USE_V2 ? THEME.PRIMARY_COLOR : '#e75830',
                    fontWeight: '600',
                }}>
                    {this.getFilters().isFiltering()
                        ? 'Vi har desverre ingen kurs som passer dine valg. Forsøk å fjerne filtreringen.'
                        : 'Vi har dessverre ingen kurs tilgjengelige' + (this.props.child ? ' for ' + this.props.child.name : '') + '.'}
                </div>
                : null}
        </div>;
    }

    scrollToEl(el) {
        const header1 = document.getElementById('sticky-header-1');
        const header2 = document.getElementById('sticky-header-2');
        const pos = el.offsetTop - ((header1 ? header1.offsetHeight : 0) + (header2 ? header2.offsetHeight : 0));

        window.scrollTo({
            top: pos,
            behavior: 'smooth'
        });

        // scrollToElement(document.getElementById('CourseCatalog-Course-' + this.state.courseId), {
        //     offset: 0,
        //     ease: 'linear',
        //     duration: 400
        // }).on('end', (e) => {
        //     console.log('Done scrolling', e);
        // });
    }

    getFilters(): CourseCatalogFilters {
        return this.props.catalogFilters;
    }

    componentWillMount() {
        Courses.instance = this;
    }

    async componentDidMount() {
        if (window.location.hash.substr(0, 10) === '#!/course/') {
            this.setState({
                courseId: window.location.hash.substr(10)
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.courseId && prevState.courseId !== this.state.courseId) {
            this.scrollToEl(document.getElementById('CourseCatalog-Course-' + this.state.courseId));
        }
    }
}

const Header = styled.div`
            background: ${THEME.USE_V2 ? THEME.SECONDARY_COLOR : '#e6e6e6'};
            display:flex;
            min-height:50px;
            align-items:stretch;
            font-size:15px;
            cursor:pointer;

            > div:nth-child(2) {

                > div:nth-child(1){
                    flex:1;
                    padding:10px;
                    padding-left:40px;
                }

                > div:nth-child(2){
                    flex:1;
                    padding:10px;
                }

                > div:nth-child(3), h3 {
                    flex:4;
                    padding:10px;
                    font-weight:700;
                    line-height:1.1;
                }

                @media all and (max-width:767px){
                    flex-flow: column;

                    > div:nth-child(1){
                        display:block;
                        width:100%;
                        padding: 10px 20px;
                        padding-bottom:0;
                        font-size:12px;
                    }

                    > div:nth-child(2){
                        display:none;
                    }

                    > div:nth-child(3){
                        display:block;
                        width:100%;
                        padding: 10px 20px;
                        padding-top:0;
                    }

                    > div:nth-child(4){
                        display:none;
                    }
                }
            }
        `;
const Content = styled.div`
            border: 1px solid #e6e6e6;
            border-top:0;
        `;

class Course extends React.Component {
    render() {
        const course = this.props.course;


        return <div id={this.props.id} style={{marginBottom: '10px'}}>
            <Header
                id={`${this.props.id}-header`}
                onClick={this.props.onToggleClick}
                aria-controls={`${this.props.id}-content`}
                aria-expanded={this.props.open}
                onKeyUp={(event) => enterKeyHandle(event)}
                tabIndex={0}
            >
                <div style={{
                    width: '10px',
                    backgroundColor: course.subject && course.subject.color ? course.subject.color : '#e6e6e6'
                }}/>
                <div style={{display: 'flex', alignItems: 'center', flex: '1'}}>
                    <div>{course.yearGradeFormatted}</div>
                    <div>{course.subject ? course.subject.name : null}</div>
                    <h3>{course.name}</h3>
                </div>
                <div style={{display: 'flex', alignItems: 'center', flexShrink: '1', padding: '10px 20px'}}>
                    <Icon style={{
                        color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#777',
                        fontSize: '30px',
                    }}>{this.props.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                </div>
            </Header>
            {this.props.open
                ? <Content id={`${this.props.id}-content`} aria-labelledby={`${this.props.id}-header`}>
                    {course.viewingFormat === 'matrix'
                        ? <MatrixCourse {...this.props} />
                        : <ListCourse {...this.props}/>}

                    {this.props.child === null && (this.props.enrollmentGroup.isDirectBooking || this.props.enrollmentGroup.isEnrollment)
                        ? <div style={{borderTop: '1px solid #DDD', margin: '0 30px', marginTop: '-20px'}}>
                            <BookingStatus{...this.props}/>
                        </div>
                        : null}
                </Content>
                : null}
            {this.props.open && (this.props.enrollmentGroup.isViewing || (ShoppingCart.data && ShoppingCart.data.bookingDenied))
                ? <div style={{border: '1px solid #DDD', marginTop: '10px'}}>
                    <BookingStatus{...this.props} style={{padding: '0 30px'}}/>
                </div>
                : null}
        </div>;
    }
}

class BookingStatus extends React.Component {
    render() {

        return <div style={$.extend({
            display: 'flex',
            alignItems: 'center',
            minHeight: '80px',
            position: 'relative'
        }, {}, this.props.style)}>
            <div style={{display: 'flex', alignItems: 'center', flex: '1'}}>
                <div style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '10px',
                    backgroundColor: '#57585A',
                    padding: '0',
                }}/>
                <div style={{padding: '10px', fontSize: '15px', ...(THEME.USE_V2 ? { color: THEME.TEXT_COLOR } : {})}}> = Tilgjengelige kurs</div>
            </div>
            {!this.props.child
                ? (UserSession.isAuthenticated()
                    ? <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{color: THEME.USE_V2 ? THEME.PRIMARY_COLOR : '#e75830', paddingRight: '20px', fontSize: '18px', fontWeight: '600'}}>
                            Meld på
                        </div>
                        <OpenCourseInBookingPortalButton course={this.props.course}
                                                         enrollmentGroup={this.props.enrollmentGroup}/>
                    </div>
                    : <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{color: THEME.USE_V2 ? THEME.PRIMARY_COLOR : '#e75830', paddingRight: '20px', fontSize: '18px', fontWeight: '600'}}>
                            Logg inn for påmelding
                        </div>
                        <Button href={JsonApi.endpoint} raised color={THEME.USE_V2 ? 'primary' : 'accent'} style={{color: '#FFF'}}>
                            Logg inn
                        </Button>
                    </div>)
                : null}

            {this.props.enrollmentGroup.nextStep ? <div style={{
                position: 'absolute',
                left: '0',
                top: '0',
                width: '100%',
                height: '100%',
                background: THEME.USE_V2 ? COLORS.YELLOW : '#fbe6e0',
                opacity: '0.8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {this.props.enrollmentGroup.nextStep
                    ? <div style={{
                        color: THEME.USE_V2 ? THEME.PRIMARY_COLOR : '#e75830',
                        textAlign: 'center',
                        fontSize: '18px',
                        fontWeight: '600',
                        padding: '0 100px'
                    }}>
                        <div dangerouslySetInnerHTML={{__html: this.props.enrollmentGroup.nextStep.overlayText}}/>
                    </div>
                    : null}
            </div> : null}
        </div>;

    }
}

class OpenCourseInBookingPortalButton extends React.Component {
    state = {newOpened: false};

    render() {
        if (UserSession.data.isStudent) {
            return <Button
                raised
                color={'accent'}
                href={JsonApi.endpoint + '/child/' + UserSession.data.user.id + '/order/' + this.props.enrollmentGroup._id + '#!/course/' + this.props.course._id}
            >
                Velg kurs
            </Button>
        }

        if (!UserSession.data.children) {
            return <Button
                raised
                color={'accent'}
                href={JsonApi.endpoint}
            >
                Gå til påmeldingsportal
            </Button>
        }

        return <Manager>
            <Target>
                <Button
                    raised
                    color={THEME.USE_V2 ? 'primary' : 'accent'}
                    onClick={() => {
                        this.setState({newOpened: true});
                    }}
                >
                    Velg barn
                </Button>
            </Target>
            <Popper
                placement="top-end"
                eventsEnabled={this.state.newOpened}
                style={this.state.newOpened ? {} : {pointerEvents: 'none'}}
            >
                <ClickAwayListener onClickAway={() => this.setState({newOpened: false})}>
                    <Grow in={this.state.newOpened} style={{transformOrigin: '0 0 0'}}>
                        <Paper>
                            <MenuList role="menu">
                                {UserSession.data.children && UserSession.data.children.map(child => {
                                    return <ListItem button component={'a'}
                                                     href={JsonApi.endpoint + '/child/' + child.id + '/order/' + this.props.enrollmentGroup._id + '#!/course/' + this.props.course._id}>
                                        <ListItemIcon>
                                            <Icon>person</Icon>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={<span style={{
                                                fontSize: '15px',
                                                color: 'rgba(0, 0, 0, 0.87)'
                                            }}>{child.name}</span>}
                                        />
                                    </ListItem>;
                                })}
                                <ListItem button component={'a'}
                                          href={JsonApi.endpoint + '/?page=newChild&completeUrl=' + encodeURIComponent('/child/{{childId}}/order/' + this.props.enrollmentGroup._id + '#!/course/' + this.props.course._id)}>
                                    <ListItemIcon>
                                        <Icon>add_circle_outline</Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<span style={{
                                            fontSize: '15px',
                                            color: 'rgba(0, 0, 0, 0.87)'
                                        }}>Legg til elev</span>}
                                    />
                                </ListItem>
                            </MenuList>
                        </Paper>
                    </Grow>
                </ClickAwayListener>
            </Popper>
        </Manager>;
    }
}

export class MobileShoppingCartWrapper extends React.Component {
    render() {
        return <BookingProcessEscapeMessage {...this.props}/>;
    }

    componentDidMount() {
        ReactDOM.render(
            <SummerSchoolThemeProvider>
                <MobileShoppingCartButton {...this.props}/>
            </SummerSchoolThemeProvider>,
            document.getElementById('SS-Header-CartWidget')
        );
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(document.getElementById('SS-Header-CartWidget'));
    }
}

export class MobileShoppingCartButton extends React.Component {
    static instance: MobileShoppingCartButton;

    opened = false;

    render() {
        const Div = styled.div`
           span:not(.material-icons){
           width:16px;
           height:16px;
           top:-6px;
           right:-6px;
           }
        `;

        return <div>
            <div style={{margin: '-2px'}}>
                <IconButton onClick={() => {
                    if (this.opened) {
                        $('.SS-OrderReactView-Wrapper').show();
                        ReactDOM.unmountComponentAtNode(document.getElementById('SS-MobileCart'));
                        this.opened = false;
                    } else {
                        $('.SS-OrderReactView-Wrapper').hide();
                        ReactDOM.render(
                            <SummerSchoolThemeProvider>
                                <MobileShoppingCartFrame
                                    backButton={() => {
                                        $('.SS-OrderReactView-Wrapper').show();
                                        ReactDOM.unmountComponentAtNode(document.getElementById('SS-MobileCart'));
                                        this.opened = false;
                                    }}
                                    {...this.props}/>
                            </SummerSchoolThemeProvider>,
                            document.getElementById('SS-MobileCart')
                        );
                        this.opened = true;
                    }
                }}>
                    <Div>
                        <Badge badgeContent={ShoppingCart.data.lines.length} color="accent">
                            <Icon style={{color: '#1f1916'}}>shopping_cart</Icon>
                        </Badge>
                    </Div>
                </IconButton>
            </div>
        </div>;
    }

    componentDidMount() {
        MobileShoppingCartButton.instance = this;
    }
}

export class TabletShoppingCartIcon extends React.Component {
    static instance: TabletShoppingCartIcon;

    render() {
        if (ShoppingCart.data.lines.length > 0) {
            return <Badge badgeContent={ShoppingCart.data.lines.length} color="accent">
                <Icon style={{color: '#FFF'}}>shopping_cart</Icon>
            </Badge>;
        } else {
            return <Icon style={{color: '#FFF'}}>shopping_cart</Icon>;
        }
    }

    componentDidMount() {
        TabletShoppingCartIcon.instance = this;
    }
}

export class MobileShoppingCartFrame extends React.Component {
    render() {
        return <div>
            <ShoppingCart {...this.props}/>
        </div>;
    }
}

const bookingDeniedMessages = {
    registered: 'Påmelding allerede registrert',
    canceled: 'Påmelding avbrutt'
};

export class ShoppingCartWrapper extends React.Component {
    static instance: ShoppingCartWrapper;
    state = {mobile: null, collapsible: null, expanded: null, bookingDeniedClosed: false};

    render() {
        if (!ShoppingCart.data)
            return <div/>;

        if (ShoppingCart.data.bookingDenied) {
            if (this.state.bookingDeniedClosed)
                return <div/>;

            const deniedMessage = 'undefined' !== typeof ShoppingCart.data.bookingDeniedType && 'string' === typeof bookingDeniedMessages[ShoppingCart.data.bookingDeniedType] ? bookingDeniedMessages[ShoppingCart.data.bookingDeniedType] : bookingDeniedMessages.registered;

            return <Dialog open onClose={() => {
                this.setState({bookingDeniedClosed: true});
            }}>
                <DialogTitle>{deniedMessage}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div dangerouslySetInnerHTML={{__html: ShoppingCart.data.bookingDenied}}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={() => this.setState({bookingDeniedClosed: true})}>Lukk</Button>
                </DialogActions>
            </Dialog>;
        }

        if (this.state.mobile === true) {
            return <MobileShoppingCartWrapper {...this.props} />;
        } else if (this.state.collapsible === true) {

            const Div = styled.div`
            
                position: fixed;
                right: 0px;
                top: 5%;
                height: 90%;
                display: flex;
                align-items: center;
                transition-delay:1s;
                z-index: 1300;
                
                transition: transform 1s;
                transform: translate(350px, 0px);
            
                &:hover, &.expanded {
                    transform: translate(0, 0);
                }      
                  
            
            `;

            return <Div className={this.state.expanded ? 'expanded' : ''}>
                <div style={{minHeight: '400px', maxHeight: '100%', display: 'flex'}}>
                    <div style={{
                        maxHeight: '100%',
                        borderRadius: '2px',
                        // boxShadow: '0px 0px 77px 0px rgba(170,170,170,1), 0px 0px 30px 0px rgba(100,100,100,1)',
                        // boxShadow:'0px 8px 10px rgba(0,0,0,0.2), 0px 6px 30px rgba(0,0,0,0.12), 0px 16px 24px rgba(0,0,0,0.14)',
                        boxShadow: '0px 0px 102px -14px rgba(0,0,0,1)',
                        position: 'relative'
                    }}>
                        <div style={{
                            position: 'absolute',
                            top: '0px',
                            left: '-64px',
                            background: '#006275',
                            width: '64px',
                            height: '64px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: '0px 0px 102px -14px rgba(0,0,0,1)',
                        }}>
                            <TabletShoppingCartIcon/>
                        </div>
                        <div className={'ShoppingCartWrapperScrollBottom'} style={{
                            width: '350px',
                            background: '#FFF',
                            maxHeight: '100%',
                            overflow: 'auto',
                            padding: '10px'
                        }}>
                            <BookingProcessEscapeMessage {...this.props}/>
                            <ShoppingCart {...this.props}/>
                        </div>
                    </div>
                </div>
            </Div>;

        } else {
            return <div style={{
                position: 'fixed',
                right: '0',
                top: '5%',
                width: '350px',
                height: '90%',
                display: 'flex',
                alignItems: 'center',
                zIndex: '1300'
            }}>
                <div className={'ShoppingCartWrapperScrollBottom'} style={{
                    flex: '1',
                    maxHeight: '100%',
                    borderRadius: '2px',
                    // boxShadow: '0px 0px 77px 0px rgba(170,170,170,1), 0px 0px 30px 0px rgba(100,100,100,1)',
                    // boxShadow:'0px 8px 10px rgba(0,0,0,0.2), 0px 6px 30px rgba(0,0,0,0.12), 0px 16px 24px rgba(0,0,0,0.14)',
                    boxShadow: '0px 0px 102px -14px rgba(0,0,0,1)',
                    overflow: 'auto'
                }}>
                    <div style={{width: '100%', background: '#FFF', padding: '10px'}}>
                        <BookingProcessEscapeMessage {...this.props}/>
                        <ShoppingCart {...this.props}/>
                    </div>
                </div>
            </div>;
        }
    }

    componentWillMount() {
        ShoppingCartWrapper.instance = this;
    }

    expand() {
        this.setState({expanded: true});
    }

    collapse() {
        this.setState({expanded: false});
    }

    restore() {
        this.setState({expanded: null});
    }

    pop() {
        this.expand();
        setTimeout(() => this.restore(), 1000);
    }

    async componentDidMount() {
        await ShoppingCart.updateData(this.props.child.id, this.props.enrollmentGroup._id);
        $(window).resize(() => {
            const width = $(window).width();
            this.setState({
                collapsible: width < 1800,
                mobile: width <= 768
            });
        }).resize();
    }

    componentDidUpdate() {
        $('.ShoppingCartWrapperScrollBottom').scrollTop(1000);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.mobile !== nextState.mobile
            || this.state.collapsible !== nextState.collapsible
            || this.state.expanded !== nextState.expanded
            || this.state.bookingDeniedClosed !== nextState.bookingDeniedClosed;
    }
}