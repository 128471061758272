import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "material-ui";
import JsonApi from "../common/JsonApi";
import SubgroupSelector from "../common/SubgroupSelector";
import BirthdateField from "../common/BirthdateField";
import GenderField from "../common/GenderField";
import { THEME } from '../common/Constants';
import SummerSchoolThemeProvider from '../common/SummerSchoolThemeProvider';

export default class NewStudentModal extends React.Component {
    state = {
        errors: [],
        notificationsEnabled: true,
        showOtherSchoolField: false
    };

    saving = false;

    render() {
        const dialog = <Dialog onClose={() => {
            this.props.handleRequestClose();
        }} open>
            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }} style={{margin: '0'}}>
                <DialogTitle>Ny elev</DialogTitle>
                <DialogContent style={{maxWidth: '350px'}}>
                    <TextField
                        label="Fornavn"
                        InputLabelProps={{ htmlFor: 'firstname' }}
                        id='firstname'
                        error={this.state.errors.indexOf('firstname') !== -1}
                        helperText={this.state.errors.indexOf('firstname') !== -1 ? 'Påkrevd' : ''}
                        fullWidth
                        autoFocus
                        onChange={(event) => {
                            this.setState({firstname: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="Etternavn"
                        InputLabelProps={{ htmlFor: 'surname' }}
                        id='surname'
                        error={this.state.errors.indexOf('surname') !== -1}
                        helperText={this.state.errors.indexOf('surname') !== -1 ? 'Påkrevd' : ''}
                        fullWidth
                        onChange={(event) => {
                            this.setState({surname: event.target.value});
                        }}
                        margin="normal"
                    />

                    <SubgroupSelector
                        error={this.state.errors.indexOf('student_role') !== -1}
                        helperText={this.state.errors.indexOf('student_role') !== -1 ? 'Påkrevd' : ''}
                        onChange={(subgroupId) => {
                            this.setState({subgroupId});
                        }}
                        onChangeShowOtherSchoolField={(showOtherSchoolField) => this.setState({showOtherSchoolField})}
                    />

                    {this.state.showOtherSchoolField
                        ? <TextField
                            label="Hvilken skole"
                            InputLabelProps={{ htmlFor: 'otherSchoolName' }}
                            id='otherSchoolName'
                            fullWidth
                            error={this.state.errors.indexOf('otherSchoolName') !== -1}
                            helperText={this.state.errors.indexOf('otherSchoolName') !== -1 ? 'Påkrevd' : ''}
                            onChange={(event) => {
                                this.setState({otherSchoolName: event.target.value});
                            }}
                            margin="normal"
                        />
                        : null}

                    <BirthdateField
                        error={this.state.errors.indexOf('birthdate') !== -1}
                        helperText={this.state.errors.indexOf('birthdate') !== -1 ? 'Påkrevd' : ''}
                        showFieldLabel={true}
                        onChange={(birthdate) => {
                            this.setState({birthdate});
                        }}

                    />

                    <TextField
                        label="Mobil"
                        InputLabelProps={{ htmlFor: 'phoneNumber' }}
                        id='phoneNumber'
                        fullWidth
                        error={this.state.errors.indexOf('phoneNumber') !== -1}
                        helperText={this.state.errors.indexOf('phoneNumber') !== -1 ? 'Påkrevd' : 'Under 13 år? Bruk foresattes mobil'}
                        onChange={(event) => {
                            this.setState({phoneNumber: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="E-post"
                        InputLabelProps={{ htmlFor: 'email' }}
                        id='email'
                        fullWidth
                        helperText={'Under 13 år ? Bruk foresattes epost'}
                        onChange={(event) => {
                            this.setState({email: event.target.value});
                        }}
                        margin="normal"
                    />

                    <TextField
                        fullWidth
                        id='address'
                        label="Gateadresse"
                        InputLabelProps={{ htmlFor: 'address' }}
                        error={this.state.errors.indexOf('address') !== -1}
                        helperText={this.state.errors.indexOf('address') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({address: event.target.value});
                        }}
                        margin="normal"
                    />
                    <div style={{display: 'flex'}}>
                        <div style={{flex: '1', paddingRight: '20px'}}>
                            <TextField
                                label="Postnummer"
                                InputLabelProps={{ htmlFor: 'postalCode' }}
                                id='postalCode'
                                fullWidth
                                error={this.state.errors.indexOf('postalCode') !== -1}
                                helperText={this.state.errors.indexOf('postalCode') !== -1 ? 'Påkrevd' : ''}
                                onChange={(event) => {
                                    this.setState({postalCode: event.target.value});
                                }}
                                margin="normal"
                            />
                        </div>
                        <div style={{flex: '2'}}>
                            <TextField
                                label="Poststed"
                                InputLabelProps={{ htmlFor: 'postalName' }}
                                id='postalName'
                                fullWidth
                                error={this.state.errors.indexOf('postalName') !== -1}
                                helperText={this.state.errors.indexOf('postalName') !== -1 ? 'Påkrevd' : ''}
                                onChange={(event) => {
                                    this.setState({postalName: event.target.value});
                                }}
                                margin="normal"
                            />
                        </div>
                    </div>


                    <GenderField
                        error={this.state.errors.indexOf('gender') !== -1}
                        helperText={this.state.errors.indexOf('gender') !== -1 ? 'Påkrevd' : ''}
                        onChange={(gender) => {
                            this.setState({gender});
                        }}
                    />


                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.handleRequestClose();
                    }} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={() => {
                        this.save()
                    }} color="primary">
                        Lagre
                    </Button>
                </DialogActions>
                <input type={'submit'} style={{position: 'absolute', left: '-9999px'}} value='Submit'/>
            </form>
            {this.state.modal}
        </Dialog>;

        return THEME.USE_V2 ? <SummerSchoolThemeProvider children={dialog}/> : dialog;
    }

    save() {
        const errors = this.updateValidation();

        if (errors.length > 0 || this.saving) {
            return;
        }

        this.saving = true;

        JsonApi.post('/api/modals/new_student/process_new_student_modal', {
            childId: this.props.childId,
            firstname: this.state.firstname,
            surname: this.state.surname,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            gender: this.state.gender,
            subgroupId: this.state.subgroupId,
            address: this.state.address,
            birthdate: this.state.birthdate,
            postalCode: this.state.postalCode,
            postalName: this.state.postalName,
            notificationsEnabled: this.state.notificationsEnabled,
            otherSchoolName: this.state.showOtherSchoolField ? this.state.otherSchoolName : null
        }, (response) => {
            this.saving = false;
            if (response.errors) {
                this.setState({
                    modal: <Dialog open onClose={() => {
                        this.setState({modal: null});
                    }}>
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Feil"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <ul>
                                    {response.errors.map(error => {
                                        return <li>{error}</li>;
                                    })}
                                </ul>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({modal: null});
                            }} color="primary">
                                Lukk
                            </Button>
                        </DialogActions>
                    </Dialog>
                });
            } else if (response.success) {
                this.props.handleRequestDone(response);
            }
        })
    }

    updateValidation() {

        let errors = [];

        if (!this.state.firstname || this.state.firstname.length === 0) {
            errors.push('firstname');
        }
        if (!this.state.surname || this.state.surname.length === 0) {
            errors.push('surname');
        }

        if (!this.state.phoneNumber || this.state.phoneNumber.length < 8) {
            errors.push('phoneNumber');
        }

        // if (this.state.response.permissions.edit_email) {
        //     if ((this.state.email === undefined && !this.state.response.user.email) || (this.state.email !== undefined && this.state.email.length === 0)) {
        //         errors.push('email');
        //     }
        // }

        if (this.state.showOtherSchoolField && !this.state.otherSchoolName) {
            errors.push('otherSchoolName');
        }

        if (!this.state.birthdate) {
            errors.push('birthdate');
        }
        if (!this.state.subgroupId) {
            errors.push('student_role');
        }

        if (!this.state.address || this.state.address.length === 0) {
            errors.push('address');
        }
        if (!this.state.postalCode || this.state.postalCode.length === 0) {
            errors.push('postalCode');
        }
        if (!this.state.postalName || this.state.postalName.length === 0) {
            errors.push('postalName');
        }
        if (!this.state.gender) {
            errors.push('gender');
        }

        this.setState({errors});

        return errors;
    }
}