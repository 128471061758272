import * as React from "react";
import styled from 'styled-components';
import { THEME } from "./Constants";

const textColor = THEME.USE_V2 ? THEME.TEXT_COLOR : '#3d3d3f';

const StyledDiv = styled.div`

color: ${textColor};
margin-bottom:20px;

h1, h2, h3, h4, h5, h6 {
    font-weight:600;
    margin-bottom: 0.5em;
    color: ${textColor} !important;
}

p {
    margin-bottom: 1em;
}

h1 {
font-size:24px;
}

h2 {
font-size:21px;
}

h3 {
font-size:18px;
}

ul {
    list-style: disc outside;
    margin-left: 2em;
    margin-top: 1em;
    
    ul {
        margin-top:0.5em;
    }
}

a {
    font-weight:600;
    color: ${THEME.USE_V2 ? THEME.PRIMARY_COLOR : '#006275'};
    text-decoration:underline;
}

`;

export default class Content extends React.Component {
    render() {
        return <StyledDiv>
            <div dangerouslySetInnerHTML={{__html: this.props.html}}/>
        </StyledDiv>
    }
}