import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent} from "material-ui";
import * as ReactDOM from "react-dom";
import {LocationMatrixInfowindow} from "./MatrixCourse";

export default class LocationModal extends React.Component {
    render() {
        return <Dialog onClose={this.props.onRequestClose} open maxWidth={'md'}>
            <DialogContent style={{padding: '0'}}>
                <div id={'LocationMap'} style={{
                    width: Math.min(700, Math.round($(window).width() * 0.8)) + 'px',
                    height: Math.round(Math.min($(window).height() * 0.8, 900)) + 'px'
                }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onRequestClose}>Lukk</Button>
            </DialogActions>
        </Dialog>
    }

    componentDidMount() {

        const location = this.props.location;
        const modalId = 'LMM' + Math.round(Math.random() * 1000000);
        const _this = this;

        $('#LocationMap')
            .gmap3({
                center: [location.coordinate.lat, location.coordinate.lng],
                zoom: 12
            })
            .marker([
                {position: [location.coordinate.lat, location.coordinate.lng]}])
            .infowindow({
                position: [location.coordinate.lat, location.coordinate.lng],
                content: '<div id="' + modalId + '-' + location._id + '"></div>'
            })
            .then(function (infowindow) {
                infowindow.open(this.get(0));

                setTimeout(() => {
                    ReactDOM.render(
                        <LocationMatrixInfowindow {..._this.props} location={location}/>,
                        document.getElementById(modalId + '-' + location._id)
                    );
                }, 200);
            });
    }
}