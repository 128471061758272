import * as React from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel,
    FormGroup, FormLabel, Radio, TextField, Checkbox, RadioGroup, MenuItem, Select, Input, ListItemText
} from "material-ui";
import JsonApi from "../common/JsonApi";
import { THEME } from '../common/Constants';

export default class EditCustomFieldsModal extends React.Component {
    state = {
        response: null,
        errors: [],
        answers: null,
    };

    saving = false;

    handleChange(changes, key, multipleValue = null){
        if (multipleValue !== null){
            // For multiple checkbox and multiple select
            let answer = this.state.answers.filter(a => a.customFieldId === key);
            let answerValues = (answer[0].value !== 'undefined' && answer[0].value) ? answer[0].value : [];
            let multipleValuePosition = answerValues.indexOf(multipleValue);
            if (changes){
                changes = answerValues.concat([multipleValue]);
            }
            else {
                answerValues.splice(multipleValuePosition, 1);
                changes = answerValues;
            }
        }

        this.setState({
            answers: [
                ...this.state.answers.filter(a => a.customFieldId !== key),
                ...[{
                    'customFieldId': key,
                    'value': changes,
                }]
            ]
        });
    }

    render() {
        if (this.state.response === null)
            return <div/>;

        const dialog = <Dialog onClose={() => {
            this.props.handleRequestClose();
        }} open>
            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }} style={{ margin: '0' }}>
                <DialogTitle>Skjema for informasjon og samtykke</DialogTitle>
                <DialogContent style={{ width: '450px' }}>
                    {this.state.response.map(customField => {
                        const label = customField.name.Value;
                        const options = customField.choiceOptions;
                        const key = customField.id;
                        const answers = this.state.answers.filter(a => a.customFieldId === key);

                        switch (customField.fieldType){
                            case 'textbox':
                                return <div style={{marginBottom: '15px'}}>
                                    <FormLabel>{label}</FormLabel>
                                    <TextField
                                        fullWidth
                                        defaultValue={answers[0].value || ''}
                                        onChange={(e) => this.handleChange(e.target.value, key)}
                                    />
                                </div>;
                            case 'textarea':
                                const fieldId = `textarea-${key}`;

                                return <div style={{marginBottom: '15px'}}>
                                    <FormLabel htmlFor={fieldId}>{label}</FormLabel>
                                    <TextField
                                        id={fieldId}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        defaultValue={answers[0].value}
                                        onChange={(e) => this.handleChange(e.target.value, key)}
                                    />
                                </div>;
                            case 'checkbox':
                                return <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={answers[0].value && answers.filter(a => a.value)[0].value !== 'false'}
                                                onChange={(e) => this.handleChange(e.target.checked, key)}
                                            />
                                        }
                                        label={label}
                                    />
                                </div>;
                            case 'checkboxgroup':
                                return <div style={{marginBottom: '15px'}}>
                                    <FormLabel>{label}</FormLabel>
                                    <FormGroup>
                                        {options.map(option => {
                                            return <FormControlLabel
                                                control={<Checkbox
                                                    checked={answers.filter(a => a.value && a.value.includes(option.name)).length}
                                                    onChange={(e) => this.handleChange(e.target.checked, key, option.name)}
                                                />}
                                                label={option.name}
                                            />
                                        })}
                                    </FormGroup>
                                </div>
                            case 'dropdown':
                                let value = answers.filter(a => a.customFieldId === key)[0].value;
                                return <div style={{marginBottom: '15px'}}>
                                    <FormLabel>{label}</FormLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={value || []}
                                        input={<Input id="select-multiple-chip" label="Chip"/>}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {options.map((option) => {
                                            return <MenuItem
                                                key={option.name}
                                                value={option.name}
                                            >
                                                <Checkbox
                                                    checked={answers.filter(a => a.value && a.value.includes(option.name)).length} 
                                                    onChange={(e) => this.handleChange(e.target.checked, key, option.name)}
                                                />
                                                <ListItemText primary={option.name} />
                                            </MenuItem>
                                        })}
                                    </Select>
                                </div>
                            case 'radiogroup':
                                const labelId = `radio-buttons-group-label-${key}`;

                                return <div style={{marginBottom: '15px'}}>
                                    <FormLabel id={labelId}>{label}</FormLabel>
                                    <RadioGroup
                                        aria-labelledby={labelId}
                                        name="radio-buttons-group"
                                        onChange={(e) => this.handleChange(e.target.value, key)}
                                    >
                                        {options.map(option => {
                                            return <FormControlLabel
                                                control={<Radio checked={answers.filter(a => a.value && a.value.includes(option.name)).length}/>} 
                                                label={option.name}
                                                value={option.name}
                                            />
                                        })}
                                    </RadioGroup>
                                </div>
                            case 'numberic':
                                return <div style={{marginBottom: '15px'}}>
                                    <FormLabel>{label}</FormLabel>
                                    <TextField
                                        fullWidth
                                        defaultValue={answers[0].value || 0}
                                        id="outlined-number"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => this.handleChange(e.target.value, key)}
                                    />
                                </div>;
                            default:
                                return null;
                        }
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.handleRequestClose();
                    }} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={() => {
                        this.save()
                    }} color="primary">
                        Lagre
                    </Button>
                </DialogActions>
                <input type={'submit'} style={{position: 'absolute', left: '-9999px'}} value="submit"/>
            </form>
            {this.state.modal}
        </Dialog>;

        return THEME.USE_V2 ? <SummerSchoolThemeProvider children={dialog}/> : dialog;
    }

    componentDidMount() {
        JsonApi.post('/api/modals/edit-custom-fields/get-edit-custom-fields-data', {
            studentId: this.props.studentId,
        }, (response) => {
            this.setState({
                response: response,
                answers: response.map(customField => {
                    return {
                        'customFieldId': customField.id,
                        'value': customField.answer ? customField.answer.Value : null,
                    }
                })
            });
        })
    }

    save() {
        if (this.saving)
            return;

        this.saving = true;

        JsonApi.post('/api/modals/edit-custom-fields/save-custom-fields-data', {
            studentId: this.props.studentId,
            answers: this.state.answers
        }, (response) => {
            this.saving = false;
            if (response.errors) {
                this.setState({
                    modal: <Dialog open onClose={() => {
                        this.setState({modal: null});
                    }}>
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Feil"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <ul>
                                    {response.errors.map(error => {
                                        return <li>{error}</li>;
                                    })}
                                </ul>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({modal: null});
                            }} color="primary">
                                Lukk
                            </Button>
                        </DialogActions>
                    </Dialog>
                });
            } else if (response) {
                this.props.handleRequestClose();
            }
        })
    }
}