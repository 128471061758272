import * as React from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
    Select, MenuItem, InputLabel, FormControl
} from "material-ui";
import JsonApi from "../common/JsonApi";
import NotificationsEnabledField from "../common/NotificationsEnabledField";
import { THEME } from '../common/Constants';
import SummerSchoolThemeProvider from '../common/SummerSchoolThemeProvider';

export default class NewGuardianModal extends React.Component {
    state = {
        errors: [],
    };

    saving = false;

    render() {
        if (this.state.response === null) {
            return <div/>;
        }

        const childRelationOptions = [];

        if (this.state.relationOptions instanceof Array) {
            this.state.relationOptions.forEach(opt => childRelationOptions.push(<MenuItem value={opt}>{opt}</MenuItem>));
        }

        const dialog = <Dialog onClose={() => {
            this.props.handleRequestClose();
        }} open>
            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }} style={{margin: '0'}}>
                <DialogTitle>Ny kontaktperson</DialogTitle>
                <DialogContent style={{maxWidth: '350px'}}>
                    <TextField
                        label="Fornavn"
                        id='firstname'
                        InputLabelProps={{ htmlFor: 'firstname' }}
                        error={this.state.errors.indexOf('firstname') !== -1}
                        helperText={this.state.errors.indexOf('firstname') !== -1 ? 'Påkrevd' : ''}
                        fullWidth
                        autoFocus
                        onChange={(event) => {
                            this.setState({firstname: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="Etternavn"
                        id='surname'
                        InputLabelProps={{ htmlFor: 'surname' }}
                        error={this.state.errors.indexOf('surname') !== -1}
                        helperText={this.state.errors.indexOf('surname') !== -1 ? 'Påkrevd' : ''}
                        fullWidth
                        onChange={(event) => {
                            this.setState({surname: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="Mobil"
                        id='phoneNumber'
                        InputLabelProps={{ htmlFor: 'phoneNumber' }}
                        fullWidth
                        error={this.state.errors.indexOf('phoneNumber') !== -1}
                        helperText={this.state.errors.indexOf('phoneNumber') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({phoneNumber: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="E-post"
                        id='email'
                        InputLabelProps={{ htmlFor: 'email' }}
                        fullWidth
                        error={this.state.errors.indexOf('email') !== -1}
                        helperText={this.state.errors.indexOf('email') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({email: event.target.value});
                        }}
                        margin="normal"
                    />

                    <TextField
                        label="Gateadresse"
                        id='address'
                        InputLabelProps={{ htmlFor: 'address' }}
                        fullWidth
                        error={this.state.errors.indexOf('address') !== -1}
                        helperText={this.state.errors.indexOf('address') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({address: event.target.value});
                        }}
                        margin="normal"
                    />
                    <div style={{display: 'flex'}}>
                        <div style={{flex: '1', paddingRight: '20px'}}>
                            <TextField
                                label="Postnummer"
                                id='postalCode'
                                InputLabelProps={{ htmlFor: 'postalCode' }}
                                fullWidth
                                error={this.state.errors.indexOf('postalCode') !== -1}
                                helperText={this.state.errors.indexOf('postalCode') !== -1 ? 'Påkrevd' : ''}
                                onChange={(event) => {
                                    this.setState({postalCode: event.target.value});
                                }}
                                margin="normal"
                            />
                        </div>
                        <div style={{flex: '2'}}>
                            <TextField
                                label="Poststed"
                                id='postalName'
                                InputLabelProps={{ htmlFor: 'postalName' }}
                                fullWidth
                                error={this.state.errors.indexOf('postalName') !== -1}
                                helperText={this.state.errors.indexOf('postalName') !== -1 ? 'Påkrevd' : ''}
                                onChange={(event) => {
                                    this.setState({postalName: event.target.value});
                                }}
                                margin="normal"
                            />
                        </div>
                    </div>

                    {this.state.response && this.state.response.child && <FormControl margin="normal" fullWidth>
                        <InputLabel>{'Relasjon til ' + this.state.response.child.name}</InputLabel>
                        <Select
                            label={'Relasjon til ' + this.state.response.child.name}
                            value={this.state.relation}
                            onChange={evt => this.setState({relation: evt.target.value})}
                        >
                            {childRelationOptions}
                        </Select>
                    </FormControl>}

                    <NotificationsEnabledField
                        error={this.state.errors.indexOf('notificationsEnabled') !== -1}
                        helperText={this.state.errors.indexOf('notificationsEnabled') !== -1 ? 'Påkrevd' : ''}
                        onChange={(notificationsEnabled) => {
                            this.setState({notificationsEnabled});
                        }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.handleRequestClose();
                    }} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={() => {
                        this.save()
                    }} color="primary">
                        Lagre
                    </Button>
                </DialogActions>
                <input type={'submit'} style={{position: 'absolute', left: '-9999px'}} value='Submit'/>
            </form>
            {this.state.modal}
        </Dialog>;

        return THEME.USE_V2 ? <SummerSchoolThemeProvider children={dialog}/> : dialog;
    }

    componentDidMount() {
        JsonApi.post('/api/modals/new_guardian/get_new_guardian_modal_data', {
            childId: this.props.childId,
        }, (response) => {
            this.setState({
                response,
                relationOptions: response.relationOptions,
                relation: response.relationOptions[0]
            });
        });
    }

    save() {
        if (this.updateValidation().length > 0 || this.saving)
            return;

        this.saving = true;

        JsonApi.post('/api/modals/new_guardian/process_new_guardian_modal', {
            childId: this.props.childId,
            firstname: this.state.firstname,
            surname: this.state.surname,
            phoneNumber: this.state.phoneNumber,
            address: this.state.address,
            postalCode: this.state.postalCode,
            relation: this.state.relation,
            postalName: this.state.postalName,
            email: this.state.email,
            notificationsEnabled: this.state.notificationsEnabled,
        }, (response) => {
            this.saving = false;
            if (response.errors) {
                this.setState({
                    modal: <Dialog open onClose={() => {
                        this.setState({modal: null});
                    }}>
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Feil"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <ul>
                                    {response.errors.map(error => {
                                        return <li>{error}</li>;
                                    })}
                                </ul>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({modal: null});
                            }} color="primary">
                                Lukk
                            </Button>
                        </DialogActions>
                    </Dialog>
                });
            } else if (response.success) {
                this.props.handleRequestDone({
                    user: {
                        firstname: this.state.firstname
                    }
                });
                location.reload();
            }
        })
    }

    updateValidation() {

        let errors = [];

        if (!this.state.firstname || this.state.firstname.length === 0) {
            errors.push('firstname');
        }
        if (!this.state.surname || this.state.surname.length === 0) {
            errors.push('surname');
        }
        if (!this.state.phoneNumber || this.state.phoneNumber.length === 0) {
            errors.push('phoneNumber');
        }
        if (!this.state.address || this.state.address.length === 0) {
            errors.push('address');
        }
        if (!this.state.postalCode || this.state.postalCode.length === 0) {
            errors.push('postalCode');
        }
        if (!this.state.postalName || this.state.postalName.length === 0) {
            errors.push('postalName');
        }
        if (!this.state.relation || this.state.relation.length === 0) {
            errors.push('relation');
        }
        if (this.state.notificationsEnabled !== true && this.state.notificationsEnabled !== false) {
            errors.push('notificationsEnabled');
        }

        this.setState({errors});

        return errors;
    }
}