import { attachEnterKeyEventHandle, enterKeyHandle } from "./utils/KeyUtils";
import * as ReactDOM from "react-dom";
import React from "react";
import jQuery from 'jquery';
import {Button} from 'material-ui';

import EditGuardianModal from "./modals/EditGuardianModal";
import CompleteProfileModal_Guardian from "./modals/CompleteProfileModal_Guardian";
import NewGuardianModal from "./modals/NewGuardianModal";
import ChildProfile_ContactPersons from "./widgets/ChildProfile_ContactPersons";
import NonFeaturedEnrollmentGroups from "./widgets/NonFeaturedEnrollmentGroups";
import FeaturedEnrollmentGroupsActions from "./widgets/FeaturedEnrollmentGroupsActions";
import CourseCatalogWrapper from "./widgets/course-catalog/CourseCatalogWrapper";
import QuestionsPage from "./widgets/checkout/QuestionsPage";
import CheckoutPage from "./widgets/checkout/CheckoutPage";
import SummerSchoolThemeProvider from "./common/SummerSchoolThemeProvider";
import JsonApi from "./common/JsonApi";
import UserSession from "./common/UserSession";
import {LocationName} from "./widgets/course-catalog/MatrixCourse";
import CompleteProfileModal_Student from "./modals/CompleteProfileModal_Student";
import CustomFieldsAnswersWrapper from "./common/CustomFieldsAnswersWrapper";
import EditStudentModal from "./modals/EditStudentModal";
import NewStudentModal from "./modals/NewStudentModal";
import LocationListPage from "./pages/LocationListPage";
import RemoveParticipantModal from "./modals/RemoveParticipantModal";
import EditCustomFieldsModal from "./modals/EditCustomFieldsModal";

if (!window.jQuery)
    window.jQuery = jQuery;
if (!window.$)
    window.$ = window.jQuery;

require('gmap3');

window.enterKeyHandle = enterKeyHandle;
$(() => attachEnterKeyEventHandle());

window.openNewGuardianModal = (childId, onDone) => {
    $('body').append('<div id="new-guardian-modal"></div>');

    ReactDOM.render(
        <NewGuardianModal
            childId={childId}
            handleRequestClose={() => {
                ReactDOM.unmountComponentAtNode(document.getElementById('new-guardian-modal'));
            }}
            handleRequestDone={() => {
                console.log(onDone);
                onDone();
            }}
        />,
        document.getElementById('new-guardian-modal')
    );
};
window.openNewStudentModal = (onDone) => {
    $('body').append('<div id="new-student-modal"></div>');

    ReactDOM.render(
        <NewStudentModal
            handleRequestClose={() => {
                ReactDOM.unmountComponentAtNode(document.getElementById('new-student-modal'));
            }}
            handleRequestDone={(res) => {
                onDone(res);
            }}
        />,
        document.getElementById('new-student-modal')
    );
};
window.openEditGuardianModal = (userId, onDone) => {
    $('body').append('<div id="edit-guardian-modal"></div>');

    ReactDOM.render(
        <EditGuardianModal
            userId={userId}
            handleRequestClose={() => {
                ReactDOM.unmountComponentAtNode(document.getElementById('edit-guardian-modal'));
            }}
            handleRequestDone={() => {
                console.log(onDone);
                onDone();
            }}
        />,
        document.getElementById('edit-guardian-modal')
    );
};
window.openEditGuardianModal_UserRole = (userRoleId, onDone) => {
    $('body').append('<div id="edit-guardian-modal"></div>');

    ReactDOM.render(
        <EditGuardianModal
            userRoleId={userRoleId}
            handleRequestClose={() => {
                ReactDOM.unmountComponentAtNode(document.getElementById('edit-guardian-modal'));
            }}
            handleRequestDone={() => {
                console.log(onDone);
                onDone();
            }}
        />,
        document.getElementById('edit-guardian-modal')
    );
};
window.openEditStudentModal = (userId, onDone) => {
    $('body').append('<div id="edit-student-modal"></div>');

    ReactDOM.render(
        <EditStudentModal
            userId={userId}
            handleRequestClose={() => {
                ReactDOM.unmountComponentAtNode(document.getElementById('edit-student-modal'));
            }}
            handleRequestDone={() => {
                console.log(onDone);
                onDone();
            }}
        />,
        document.getElementById('edit-student-modal')
    );
};

window.openCompleteProfileModal_Student = () => {
    $('body').append('<div id="complete-profile-modal"></div>');

    ReactDOM.render(
        <CompleteProfileModal_Student
            handleRequestDone={() => {
                location.reload();
            }}
        />,
        document.getElementById('complete-profile-modal')
    );
};

window.openCompleteProfileModal_Guardian = () => {
    $('body').append('<div id="complete-profile-modal"></div>');

    ReactDOM.render(
        <CompleteProfileModal_Guardian
            handleRequestDone={() => {
                location.reload();
            }}
        />,
        document.getElementById('complete-profile-modal')
    );
};

window.attachContactPersons = (data) => {
    ReactDOM.render(
        <ChildProfile_ContactPersons
            data={data}
        />,
        document.getElementById('SS-FrontPage-Box-ContactPersons')
    );
};
window.attachNonFeaturedEnrollmentGroup = (data) => {
    ReactDOM.render(
        <NonFeaturedEnrollmentGroups
            data={data}
        />,
        document.getElementById('SS-SelectEnrollmentGroup-NonFeaturedEnrollmentGroups-List')
    );
};
window.attachFeaturedEnrollmentGroupActions = (data) => {
    ReactDOM.render(
        <FeaturedEnrollmentGroupsActions
            data={data}
        />,
        document.getElementById('SS-SelectEnrollmentGroup-FeaturedEnrollmentGroup-Actions')
    );
};

window.attachFeaturedEnrollmentGroupActionsStandalone = (id, data) => {
    ReactDOM.render(
        <FeaturedEnrollmentGroupsActions
            data={data}
        />,
        document.getElementById(id)
    );
};

window.attachCourseCatalog = ($el, data) => {
    ReactDOM.render(
        <CourseCatalogWrapper
            {...data}
        />,
        $el
    );
};
window.attachQuestionsPage = ($el, data) => {
    ReactDOM.render(
        <QuestionsPage
            {...data}
        />,
        $el
    );
};
window.attachCheckoutPage = ($el, data) => {
    ReactDOM.render(
        <CheckoutPage
            {...data}
        />,
        $el
    );
};

window.attachCustomFieldsAnswers = ($el, data) => {
    ReactDOM.render(
        <CustomFieldsAnswersWrapper
            {...data}
        />,
        $el
    );
};
window.attachLocationListPage = ($el, data) => {
    ReactDOM.render(
        <LocationListPage
            {...data}
        />,
        $el
    );
};
window.attachRemoveParticipantModal = (data) => {
    const id = 'remove-participant-modal-' + Math.round(Math.random() * 100000);
    $('body').append('<div id="'+id+'"></div>');
    ReactDOM.render(
        <RemoveParticipantModal
            {...data}
        />,
        document.getElementById(id)
    );
};
window.openCustomFieldsModal = (studentId, onDone) => {
    $('body').append('<div id="edit-custom-fields-modal"></div>');

    ReactDOM.render(
        <EditCustomFieldsModal
            studentId={studentId}
            handleRequestClose={() => {
                ReactDOM.unmountComponentAtNode(document.getElementById('edit-custom-fields-modal'));
            }}
            handleRequestDone={() => {
                console.log(onDone);
                ReactDOM.unmountComponentAtNode(document.getElementById('edit-custom-fields-modal'));
            }}
        />,
        document.getElementById('edit-custom-fields-modal')
    );
};
window.React = React;
window.ReactDOM = ReactDOM;
window.Button = Button;
window.JsonApi = JsonApi;
window.SummerSchoolThemeProvider = SummerSchoolThemeProvider;
window.UserSession = UserSession;
window.LocationName = LocationName;

window.addEventListener('load', () => {
    let stickyHeader1 = document.getElementById('sticky-header-1');
    const stickyHeader2 = document.getElementById('sticky-header-2');
    const mainContent = document.getElementById('content');
    let isWordpressSite = false;

    if (!stickyHeader1) {
        stickyHeader1 = document.getElementById('header_main_alternate');
        isWordpressSite = true;
    }

    if (stickyHeader1 && stickyHeader2 && mainContent) {
        const sticky1 = stickyHeader1.offsetTop;
        const topHeader2 = stickyHeader1.clientHeight;
        const bodyEl = isWordpressSite ? document.querySelector('body#top') : null;
        const stickyStyles = 'position: fixed; top: 0; z-index: 1300; width: ' + (isWordpressSite ? 'calc(100% - 15px);' : '100%;');

        (isWordpressSite ? bodyEl : window).addEventListener('scroll', (e) => {
            if ((isWordpressSite ? bodyEl.scrollTop : window.pageYOffset) > sticky1) {
                stickyHeader1.style = stickyStyles + ' background: #fff;';
                stickyHeader2.style = stickyStyles + ' top: ' + topHeader2 + 'px;';
                mainContent.style = 'padding-top: ' + (stickyHeader1.clientHeight + stickyHeader2.clientHeight) + 'px;';
            } else {
                stickyHeader1.style = '';
                stickyHeader2.style = '';
                mainContent.style = '';
            }
        });
    }
});