import * as React from "react";
import {FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Radio} from "material-ui";

export default class GenderField extends React.Component {
    state = {gender: null};

    render() {

        return <div style={{display: 'flex', marginTop: '16px', marginBottom: '8px'}}>

            <FormControl component="fieldset" style={{marginTop: '25px'}}
                         disabled={this.props.disabled} error={this.props.error}>
                <FormLabel component="legend" style={{color: '#57585a'}}>Kjønn</FormLabel>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={this.state.gender === 'M'}
                                onChange={(event) => {
                                    this.setState({
                                        gender: 'M'
                                    }, () => {
                                        this.props.onChange(this.state.gender);
                                    });
                                }}
                            />
                        }
                        label={'Gutt'}
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={this.state.gender === 'F'}
                                onChange={(event) => {
                                    this.setState({
                                        gender: 'F'
                                    }, () => {
                                        this.props.onChange(this.state.gender);
                                    });
                                }}
                            />
                        }
                        label={'Jente'}
                    />
                </FormGroup>
                {this.props.helperText ? <FormHelperText error>{this.props.helperText}</FormHelperText> : null}
            </FormControl>
        </div>
    }

    componentWillMount() {
        this.setState({
            gender: this.props.defaultValue
        });
    }
}