import * as React from "react";
import $ from 'jquery';
import styled from 'styled-components';
import moment from "moment-timezone";
import {Checkbox, Icon, MenuItem, FormControl, Select} from "material-ui";
import { AREAS, BREAKPOINTS, THEME } from "../../common/Constants";
import { Tooltip } from "material-ui";
import { withStyles } from "material-ui";

export default class CourseCatalogFilters extends React.Component {
    state = {
        yearGroups: [],
        subjects: [],
        weeks: []
    };

    render() {
        const catalog = this.props.catalog;
        const isMobile = $(window).width() < BREAKPOINTS.TABLET;
        const showYeargroups = catalog.yearGroups.length > 1 && (this.props.child === null || this.props.isSuperAdmin||this.props.isAdministrator);
        const showSubjects = catalog.subjects.length > 1;
        const showWeeks = Object.values(catalog.weeks).length > 1;

        if (!showYeargroups && !showSubjects && !showWeeks)
            return <div/>;

        return <CourseCatalogFiltersContainer {...this.props}>
            {showYeargroups
                ? <FilterGroup label={'Klassetrinn:'}>
                    <Filter active={this.state.yearGroups.length === 0} onClick={() => {
                        this.setState({yearGroups: []});
                        this.props.filtersUpdated();
                    }}>Alle</Filter>

                    {catalog.yearGroups.map(yearGroup => {
                        let isActive = this.state.yearGroups.indexOf(yearGroup) !== -1;

                        return <Filter key={yearGroup} active={isActive} onClick={() => {
                            if (isActive)
                                this.state.yearGroups.splice(this.state.yearGroups.indexOf(yearGroup), 1);
                            else
                                this.state.yearGroups.push(yearGroup);

                            this.setState({yearGroups: this.state.yearGroups});
                            this.props.filtersUpdated();
                        }}>
                            {yearGroup > 10 ? 'VGS' + (yearGroup - 10) : yearGroup}
                        </Filter>;
                    })}
                </FilterGroup>
                : null}
            {showSubjects
                ? <FilterGroup label={'Vis tema:'}>
                    <Filter active={this.state.subjects.length === 0} onClick={() => {
                        this.setState({subjects: []});
                        this.props.filtersUpdated();
                    }}>Alle</Filter>

                    {catalog.subjects.map(subject => {
                        let isActive = this.state.subjects.indexOf(subject.code) !== -1;

                        return <Filter
                            key={subject.code}
                            active={isActive}
                            color={subject.color}
                            description={subject.description}
                            onClick={() => {
                                if (isActive)
                                    this.state.subjects.splice(this.state.subjects.indexOf(subject.code), 1);
                                else
                                    this.state.subjects.push(subject.code);

                                this.setState({subjects: this.state.subjects});
                                this.props.filtersUpdated();
                            }}
                        >
                            {subject.name}
                        </Filter>;
                    })}
                </FilterGroup>
                : null}
            {showWeeks
                ? <FilterGroup label={'Vis uke:'}>
                    <Filter active={this.state.weeks.length === 0} onClick={() => {
                        this.setState({weeks: []});
                        this.props.filtersUpdated();
                    }}>Alle</Filter>

                    {Object.keys(catalog.weeks).map(weekId => {
                        const week = catalog.weeks[weekId];
                        let isActive = this.state.weeks.indexOf(weekId) !== -1;

                        return <Filter
                            key={weekId}
                            active={isActive}
                            onClick={() => {
                                if (isActive)
                                    this.state.weeks.splice(this.state.weeks.indexOf(weekId), 1);
                                else
                                    this.state.weeks.push(weekId);

                                this.setState({weeks: this.state.weeks});
                                this.props.filtersUpdated();
                            }}
                        >
                            Uke {week.week} ({moment(week.dateStart * 1000).tz('Europe/Oslo').format("DD.MM")}−{moment(week.dateEnd * 1000).tz('Europe/Oslo').format("DD.MM")})
                        </Filter>;
                    })}
                </FilterGroup>
                : null}
                <div style={{display: 'flex', justifyContent: 'space-between', gap: isMobile ? 0 : '20px', flexDirection: isMobile ? 'column' : 'row' }}>
                    <FilterGroup label="Bydel:" style={{ flexGrow: 1 }}>
                        <FormControl fullWidth style={{ margin: '5px 6px'}}>
                            <Select value={this.props.selectedArea} onChange={this.props.onAreaChange} onKeyUp={(event => enterKeyHandle(event))}>
                                <MenuItem key={0} value={AREAS.DEFAULT}>Alle</MenuItem>
                                {this.props.areas && this.props.areas.map((area) => (
                                    <MenuItem key={area.id} value={area.id}>
                                        {area.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FilterGroup>
                    <div style={{ display: 'flex', alignItems: "center", flexGrow: isMobile ? 1 : 2, marginLeft: isMobile ? '90px' : 0 }}>
                        <Checkbox
                            inputProps={{
                                id: 'show-available-course'
                            }}
                            checked={this.props.showAvailableCourse}
                            onClick={() => this.props.onShowAvailableCourseChange()}
                            onKeyUp={(event => enterKeyHandle(event))}
                        />
                        <label
                            htmlFor="show-available-course"
                            onClick={() => this.props.onShowAvailableCourseChange()}
                            style={{cursor: "pointer", userSelect: 'none'}}
                        >
                            Vis kun ledige kurs
                        </label>
                    </div>
                </div>
        </CourseCatalogFiltersContainer>;
    }

    isCourseShown(course, filterByGrade = false) {
        if (this.state.subjects.length > 0) {
            if (this.state.subjects.indexOf(course.subject.code) === -1)
                return false;
        }

        if (this.state.weeks.length > 0) {
            let found = false;

            this.state.weeks.forEach(weekId => {

                if (course.weeks[weekId])
                    found = true;

            });

            if (!found)
                return false;
        }

        if (this.state.yearGroups.length > 0) {
            let found = false;

            for (let i = course.yearGradeMinimum; i <= course.yearGradeMaximum; i++) {
                if (this.state.yearGroups.indexOf(i) !== -1)
                    found = true;
            }


            if (!found)
                return false;
        }

        if (filterByGrade) {
            if ('number' === typeof this.props.defaultYearGrade && !this.props.isSuperAdmin && !this.props.isAdministrator) {
                if ('number' === typeof course.yearGradeMinimum && course.yearGradeMinimum > this.props.defaultYearGrade) {
                    return false;
                }

                if ('number' === typeof course.yearGradeMaximum && course.yearGradeMaximum < this.props.defaultYearGrade) {
                    return false;
                }
            }
        } else { // Filter on year
            if (this.props.child && this.props.child.birthdate && !this.props.isSuperAdmin && !this.props.isAdministrator) {
                if (course.birthdateMinimum && course.birthdateMinimum > this.props.child.birthdate)
                    return false;
                if (course.birthdateMaximum && course.birthdateMaximum < this.props.child.birthdate)
                    return false;
            }
        }

        return true;
    }

    isWeekShown(weekId) {
        return this.state.weeks.length === 0 || this.state.weeks.indexOf(weekId) !== -1;
    }

    isFiltering() {
        return this.state.weeks.length !== 0 || this.state.yearGroups.length !== 0 || this.state.subjects.length !== 0;
    }
}

class CourseCatalogFiltersContainer extends React.Component {
    state = {expanded: null};

    render() {
        if (this.state.expanded === null)
            this.state.expanded = $(window).width() >= 1024;

        const Div = styled.div`
            border: 1px solid #a4a4a4;
            padding:10px 15px;
            margin-bottom:20px;
        `;

        return <div>

            <div style={{display: 'flex', cursor: 'pointer', alignItems: 'center', marginBottom: '14px'}}
                id="course-catalog-filters-header"
                aria-controls="course-catalog-filters-content"
                aria-expanded={this.state.expanded}
                role="button"
                onClick={() => {
                    this.setState({expanded: !this.state.expanded});
                }}
                onKeyUp={(event) => enterKeyHandle(event)}
                tabIndex={0}
            >
                <div style={{fontWeight: '600', textDecoration: 'underline'}}>Filtrer kurs</div>
                <div style={{paddingLeft: '10px'}}>
                    <Icon>{this.state.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
                </div>
                <div
                    style={{paddingLeft: '30px'}}>{this.props.child && !this.props.isSuperAdmin && !this.props.isAdministrator ? 'Viser kun kurs tilgjengelig for ' + this.props.child.firstname + (this.props.filterByGrade ? ' sitt trinn' : ' sitt fødselsår') : ''}</div>
            </div>

            {this.state.expanded ? (
                <Div id="course-catalog-filters-content" aria-labelledby="course-catalog-filters-header">
                    {this.props.children}
                </Div>
            ) : null}
        </div>;
    }
}

class FilterGroup extends React.Component {
    style = 'object' === typeof this.props.style ? this.props.style : {};

    render() {
        return <div style={{display: 'flex', alignItems: 'center', padding: '5px', ...this.style}}>
            <div style={{width: '95px', fontWeight: 600, fontSize: '15px', color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#3d3d3f'}}>{this.props.label}</div>
            <div style={{display: 'flex', flex: '1', flexWrap: 'wrap'}}>{this.props.children}</div>
        </div>;
    }
}

class Filter extends React.Component {
    render() {
        const style = $.extend({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
            border: '1px solid #a4a4a4',
            margin: '5px 6px',
            color: '#3d3d3f',
            cursor: 'pointer',
        }, this.props.active ? {
            margin: '5px',
            border: '2px solid #006275',
            background: 'rgba(0,98,117,0.2)'
        } : {});

        const styleV2 = {
            ...style,
            border: `1px solid ${THEME.SECONDARY_COLOR}`,
            color: THEME.TEXT_COLOR,
            background: THEME.SECONDARY_COLOR,
            ...(this.props.active ? {
                border: `1px solid ${THEME.PRIMARY_COLOR}`,
                color: THEME.TEXT_COLOR_WHITE,
                background: THEME.PRIMARY_COLOR
            }: {})
        };

        const selectedStyle = THEME.USE_V2 ? styleV2 : style;

        return <FilterTooltip title={this.props.description ? this.props.description : ''}><a
            className="course-catalog-filters-item"
            style={selectedStyle}
            onClick={this.props.onClick}
            onKeyUp={(event) => enterKeyHandle(event)}
            tabIndex={0}
        >
            {this.props.color
                ? <div style={{backgroundColor: this.props.color, height: '100%', width: '10px'}}/>
                : null}
            <div style={{padding: '0 17px'}}>{this.props.children}</div>
        </a></FilterTooltip>;
    }
}

const FilterTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#000',
        color: '#fff',
        boxShadow: theme.shadows[3],
        fontSize: 15,
        fontFamily: "OsloSans"
    }
}))(Tooltip);