import * as React from "react";
import JsonApi from "../common/JsonApi";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "material-ui";
import SummerSchoolThemeProvider from "../common/SummerSchoolThemeProvider";
import { THEME } from "../common/Constants";

export default class RemoveParticipantModal extends React.Component {
    state = {response: null, open: true};

    render() {
        if (this.state.response === null)
            return <div/>;

        const dialog = <Dialog open={this.state.open} onClose={() => this.setState({open: false})}>
            <DialogTitle>{this.state.response.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{this.state.response.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.setState({open: false})}>Avbryt</Button>
                <Button onClick={() => {
                    JsonApi.post('/api/remove-participant/process-remove-participant', {
                        participantId: this.props.participantId
                    }, (response) => {
                        location.reload();
                    });
                }} color={'primary'}>Bekreft</Button>
            </DialogActions>
        </Dialog>;

        return THEME.USE_V2 ? <SummerSchoolThemeProvider children={dialog}/> : dialog;
    }

    componentDidMount() {
        JsonApi.post('/api/remove-participant/get-remove-participant-data', {
            participantId: this.props.participantId
        }, (response) => {
            this.setState({response});
        })
    }
}