import * as React from "react";
import $ from 'jquery';
import {Icon, IconButton} from "material-ui";
import SummerSchoolThemeProvider from "../common/SummerSchoolThemeProvider";

export default class ChildProfile_ContactPersons extends React.Component {
    state = {guardianId: null};

    render() {
        const data = this.props.data;

        return <SummerSchoolThemeProvider>
            <div>
                {data.map((guardian, index) => {
                    const isSelected = this.state.guardianId === guardian.user.id;

                    return <div className={`child-profile-contact-person-wrapper ${index !== data.length - 1 ? 'child-profile-contact-person-wrapper-border' : ''}`}
                        key={index}
                        onClick={() => {
                            this.setState({
                                guardianId: this.state.guardianId === guardian.user.id
                                    ? null
                                    : guardian.user.id,
                            });
                        }}>
                        <div className="child-profile-contact-person-header"
                            id={`child-profile-contact-person-${guardian.user.id}-header`}
                            aria-controls={`child-profile-contact-person-${guardian.user.id}-content`}
                            aria-expanded={isSelected}
                            role="button"
                        >
                            <div className="child-profile-contact-person-name">{guardian.user.name}</div>
                            <div style={{paddingRight: '10px'}}>
                                <IconButton style={{margin: '-7px 0'}}>
                                    <Icon className="child-profile-contact-person-icon">{isSelected ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                </IconButton>
                            </div>
                        </div>
                        {isSelected
                            ? <div style={{padding: '0 28px', paddingBottom: '15px', marginTop: '-10px'}}
                                id={`child-profile-contact-person-${guardian.user.id}-content`}
                                aria-labelledby={`child-profile-contact-person-${guardian.user.id}-header`}
                            >
                                <div>E-post: {guardian.user.email ? guardian.user.email : <i>Ikke registrert</i>}</div>
                                <div>Mobil: {guardian.user.phoneNumber
                                    ? guardian.user.phoneNumber
                                    : <i>Ikke registrert</i>}</div>
                                <div style={{marginTop: '10px'}}>
                                    Motta påmeldingsvarsler: {guardian.notificationsEnabled ? 'Ja' : 'Nei'}
                                </div>
                            </div>
                            : null}
                    </div>;
                })}
            </div>
        </SummerSchoolThemeProvider>;
    }

    componentWillMount() {
        const data = this.props.data;

        if (data.length > 0) {
            this.setState({guardianId: data[0].user.id});
        }
    }
}