import * as React from "react";
import $ from 'jquery';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, TextField} from "material-ui";
import { THEME } from "./Constants";
import { RadioGroup } from "material-ui";

export default class CustomFieldsEditor extends React.Component {

    fieldObjects: FieldType[] = {};

    render() {

        const data = this.props.data;

        return <form id={'EditCustomFields'} onSubmit={(event) => {
            event.stopPropagation();
            event.preventDefault();

            if (!this.isValidationPassed())
                return;

            this.props.onSubmit();
        }}>

            {data.fields.map(field => {

                if (!field.isWritable)
                    return null;

                const props = {
                    key: field.customField._id,
                    field,
                    ...this.props,
                    ref: ref => {
                        this.fieldObjects[field.customField._id] = ref;
                    },
                    onChange: () => {
                        if (this.props.onChange)
                            this.props.onChange();
                        if (this.props.onValidationUpdated)
                            this.props.onValidationUpdated();
                    },
                };

                switch (field.fieldType.className) {
                    case 'RadioGroupCustomFieldType':
                        return <RadioGroupCustomFieldType {...props}/>;
                    case 'TextFieldCustomFieldType':
                        return <TextFieldCustomFieldType {...props}/>;
                    case 'TextBoxCustomFieldType':
                        return <TextBoxCustomFieldType {...props}/>;
                    case 'CheckboxCustomFieldType':
                        return <CheckboxCustomFieldType {...props}/>;
                    case 'CheckboxGroupCustomFieldType':
                        return <CheckboxGroupCustomFieldType {...props} />
                }
            })}

        </form>

    }

    isValidationPassed() {
        let passed = true;

        Object.values(this.fieldObjects).forEach(field => {
            if (field.isValidationPassed() === false && field.getField().isMandatory)
                passed = false;
        });

        return passed;
    }

    getSerializedData() {
        let data = {};

        Object.values(this.fieldObjects).forEach(field => {
            data[field.getCustomField()._id] = field.getValue();
        });

        return data;
    }
}

class FieldType extends React.Component {
    state = {};

    getField() {
        return this.props.field;
    }

    getFieldType() {
        return this.props.field.fieldType;
    }

    getCustomField() {
        return this.props.field.customField;
    }

    getData() {
        return this.props.data.data;
    }

    getValue() {
        if (this.state.value === undefined)
            return this.props.data.data ? this.props.data.data[this.getCustomField()._id] : null;

        return this.state.value;
    }

    setValue(value) {
        this.setState({value});
        this.props.onChange();
    }

    isValidationPassed() {
        return true;
    }
}

class CheckboxGroupCustomFieldType extends FieldType {

    getValue() {
        if (this.state.value === undefined) {
            if (this.props.data.data) {
                let data = [];
                //return this.props.data.data[this.getCustomField()._id];
                this.props.data.data[this.getCustomField()._id].forEach(value => {
                    let option = null;
                    this.getFieldType().options.options.forEach(o => {
                        if (o.name === value || o.name + ' ()' === value) {
                            data.push({
                                id: o.name,
                                text: ''
                            })
                        } else if (o.textFieldShownWhenChosen && typeof value === 'string' && value.substr(0, (o.name + ' (').length) === o.name + ' (') {
                            data.push({
                                id: o.name,
                                text: value.substr(o.name.length + 2, value.length).slice(0, -1)
                            })
                        }
                    });
                });

                return data;
            } else {
                return null;
            }
        }

        return this.state.value;
    }

    onChangeOption = (option) => {
        const values = this.state.value || this.getValue() || [];
        let item = {
            id: option.name,
            text: ''
        };

        const newValues = values.slice(0);
        let isExistedIndex = newValues.findIndex(value => value.id === option.name);
        if (isExistedIndex !== -1) {
            item = newValues[isExistedIndex];
        }

        if (newValues.find(value => value.id === option.name))
            newValues.splice(newValues.findIndex(value => value.id === option.name) , 1);
        else
            newValues.push(item);

        //this.setState({values: newValues});
        this.setValue(newValues);
    }

    render() {
        const values = this.state.value || this.getValue() || [];
        console.log(values);

        return <div>
            <div>
                <FormControl component="fieldset" style={{marginTop: '25px'}}>
                    <DescriptionLabel {...this.props} {...this.state}/>
                    <FormGroup row>
                        {this.getFieldType().options.options.map(option => {
                            let currentIndex = values.findIndex(item => item.id == option.name);
                            let currentItem = values[currentIndex];
                            const isChecked = currentIndex !== -1;

                            return <div style={{display: 'flex', width: '100%', flexDirection: 'column'}}>
                                <FormControlLabel
                                    style={{marginRight: 20}}
                                    key={option.name}
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            onChange={(event) => this.onChangeOption(option) }
                                            disabled={!this.getField().isWritable}
                                            style={{color: '#57585a'}}
                                        />
                                    }
                                    label={option.name}
                                />

                                <div style={{display: 'flex', alignItems: 'center', marginRight: 20}}>
                                    <div className={'content'}>
                                        <div dangerouslySetInnerHTML={{__html: option.description}}/>
                                    </div>
                                </div>

                                {isChecked && option.chosenDescription
                                    ? <div style={{display: 'flex', alignItems: 'center', marginRight: 20}}>
                                        <div className={'content'}>
                                            <div dangerouslySetInnerHTML={{__html: option.chosenDescription}}/>
                                        </div>
                                    </div>
                                    : null}

                                {isChecked && option.textFieldShownWhenChosen
                                    ? <div>
                                        <TextField
                                            label={'Skriv her'}
                                            defaultValue={currentItem.text}
                                            onChange={(event) => {
                                                let newValues = values.slice(0);
                                                let currentIndex = newValues.findIndex(item => item.id == option.name);
                                                let currentItem = newValues[currentIndex];

                                                currentItem.text = event.target.value;

                                                //this.setState({values: newValues})
                                                this.setValue(newValues)
                                            }}
                                            disabled={!this.getField().isWritable}
                                            fullWidth
                                            multiline
                                            // helperText="Some important text"
                                        />
                                    </div>
                                    : null}
                            </div>
                        })}
                    </FormGroup>
                </FormControl>
            </div>
        </div>
    }

    getOption() {
        return true;
    }

    isValidationPassed() {
        return this.getOption() !== null;
    }

    componentDidMount() {
        $('#EditCustomFields a').attr('target', '_blank');
    }

    componentDidUpdate() {
        $('#EditCustomFields a').attr('target', '_blank');
    }
}


class RadioGroupCustomFieldType extends FieldType {
    render() {
        const currentOption = this.getOption();
        return <div>
            <div>
                <FormControl component="fieldset" style={{marginTop: '25px'}}>
                    <DescriptionLabel {...this.props} {...this.state}/>
                    <FormGroup row>
                        {this.getFieldType().options.options.map(option => {
                            return <FormControlLabel
                                key={option.name}
                                control={
                                    <Radio
                                        checked={option === currentOption}
                                        onChange={(event) => {
                                            this.setValue(option.name);
                                        }}
                                        disabled={!this.getField().isWritable}
                                        style={{color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#57585a'}}
                                    />
                                }
                                label={option.name}
                            />
                        })}
                    </FormGroup>
                </FormControl>
            </div>

            {currentOption && currentOption.chosenDescription
                ? <div style={{maxWidth: '600px'}}>
                    <div className={'content'}>
                        <div dangerouslySetInnerHTML={{__html: currentOption.chosenDescription}}/>
                    </div>
                </div>
                : null}

            {currentOption && currentOption.textFieldShownWhenChosen
                ? <div style={{maxWidth: '600px'}}>
                    <TextField
                        label={'Skriv her'}
                        defaultValue={this.getValue().substr(currentOption.name.length + 2, this.getValue().length - currentOption.name.length - 3)}
                        onChange={(event) => {
                            this.setValue(currentOption.name + ' (' + event.target.value + ')');
                        }}
                        disabled={!this.getField().isWritable}
                        fullWidth
                        multiline
                        // helperText="Some important text"
                    />
                </div>
                : null}

        </div>
    }

    getOption() {
        const value = this.getValue();

        if (!value)
            return null;

        let option = null;
        this.getFieldType().options.options.forEach(o => {
            if (o.name === value || (o.textFieldShownWhenChosen && typeof value === 'string' && value.substr(0, (o.name + ' (').length) === o.name + ' ('))
                option = o;
        });
        return option;
    }

    isValidationPassed() {
        return this.getOption() !== null;
    }

    componentDidMount() {
        $('#EditCustomFields a').attr('target', '_blank');
    }

    componentDidUpdate() {
        $('#EditCustomFields a').attr('target', '_blank');
    }
}

class TextFieldCustomFieldType extends FieldType {
    render() {
        console.log(this);
        return <div style={{marginTop: '20px'}}>
            {this.getCustomField().description ? <DescriptionLabel {...this.props} {...this.state}/> : null}

            <div style={{maxWidth: '600px'}}>
                <TextField
                    label={!this.getCustomField().description ? this.getCustomField().name : null}
                    defaultValue={this.getValue()}
                    fullWidth
                    disabled={!this.getField().isWritable}
                    onChange={(event) => {
                        this.setValue(event.target.value);
                    }}
                    // helperText="Some important text"
                />
            </div>
        </div>;
    }

    isValidationPassed() {
        return typeof this.getValue() === 'string' && this.getValue().trim().length > 0;
    }

    componentDidMount() {
        $('#EditCustomFields a').attr('target', '_blank');
    }

    componentDidUpdate() {
        $('#EditCustomFields a').attr('target', '_blank');
    }
}


class TextBoxCustomFieldType extends FieldType {
    render() {
        console.log(this);
        return <div style={{marginTop: '20px'}}>
            {this.getCustomField().description ? <DescriptionLabel {...this.props} {...this.state}/> : null}

            <div style={{maxWidth: '600px'}}>
                <TextField
                    label={!this.getCustomField().description ? this.getCustomField().name : null}
                    defaultValue={this.getValue()}
                    multiline
                    disabled={!this.getField().isWritable}
                    fullWidth
                    onChange={(event) => {
                        this.setValue(event.target.value);
                    }}
                    // helperText="Some important text"
                />
            </div>

        </div>;
    }

    isValidationPassed() {
        return typeof this.getValue() === 'string' && this.getValue().trim().length > 0;
    }

    componentDidMount() {
        $('#EditCustomFields a').attr('target', '_blank');
    }

    componentDidUpdate() {
        $('#EditCustomFields a').attr('target', '_blank');
    }
}

class CheckboxCustomFieldType extends FieldType {
    render() {
        return <div>

            <FormControl component="fieldset" style={{marginTop: '25px'}}>
                <DescriptionLabel {...this.props} {...this.state}/>
                <RadioGroup row name={this.getCustomField()._id}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={this.getValue() === true}
                                onChange={(event) => {
                                    this.setValue(true);
                                }}
                                disabled={!this.getField().isWritable}
                                style={THEME.USE_V2 ? {} : {color: '#57585a'}}
                            />
                        }
                        label={'Ja'}
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={this.getValue() === false}
                                onChange={(event) => {
                                    this.setValue(false);
                                }}
                                disabled={!this.getField().isWritable}
                                style={THEME.USE_V2 ? {} : {color: '#57585a'}}
                            />
                        }
                        label={'Nei'}
                    />
                </RadioGroup>
            </FormControl>

            {this.getValue() === true && this.getFieldType().options.trueDescription
                ? <div className={'content'}
                       dangerouslySetInnerHTML={{__html: this.getFieldType().options.trueDescription}}/>
                : null}
            {this.getValue() === false && this.getFieldType().options.falseDescription
                ? <div className={'content'}
                       dangerouslySetInnerHTML={{__html: this.getFieldType().options.falseDescription}}/>
                : null}
        </div>
    }

    isValidationPassed() {
        return this.getValue() === true || this.getValue() === false;
    }

    componentDidMount() {
        $('#EditCustomFields a').attr('target', '_blank');
    }

    componentDidUpdate() {
        $('#EditCustomFields a').attr('target', '_blank');
    }
}

class DescriptionLabel extends React.Component {
    render() {
        return this.props.field.customField.description
            ? <div>
                <h4 style={{
                    color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#212121',
                    fontSize: '20px',
                    fontFamily: THEME.USE_V2 ? THEME.FONT : 'Roboto',
                    lineHeight: '28px',
                    fontWeight: '600',
                    marginBottom: '12px',
                }}>{this.props.field.customField.name}</h4>
                <div
                    className={'content'}
                    dangerouslySetInnerHTML={{__html: this.props.field.customField.description}}
                    style={{color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#3d3d3f', marginBottom: '0', maxWidth: '700px'}}
                />
            </div>
            : <FormLabel component="legend" style={{color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#3d3d3f'}}>{this.props.field.customField.name}</FormLabel>;
    }

    componentDidMount() {
        $('#EditCustomFields a').attr('target', '_blank');
    }

    componentDidUpdate() {
        $('#EditCustomFields a').attr('target', '_blank');
    }
}
