import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, Icon, IconButton} from "material-ui";
import styled from 'styled-components';
import LocationModal from "./LocationModal";
import ActivityApplyAction from "./ActivityApplyAction";
import CourseCatalogFilters from "./CourseCatalogFilters";
import MobileMatrixCourse from "./MobileMatrixCourse";
import * as ReactDOM from "react-dom";
import ContentFormatted from "../../common/ContentFormatted";
import { THEME, AREAS } from "../../common/Constants";

const Th = styled.th`
    padding: 10px 0;
    font-size:15px;
    border:none !important;
    text-transform:none;
    font-size:15px;
    
    &:nth-child(n+2){
        text-align: center;
    }
`;
const Td = styled.td`
    padding: 10px;
    font-size:15px;
    vertical-align: middle;
    border:none !important;
    border-top: 1px solid #e6e6e6 !important;
    font-size:15px;
`;

export default class MatrixCourse extends React.Component {
    render() {
        const catalog = this.props.catalog;
        const course = this.props.course;


        if ($(window).width() < 768)
            return <MobileMatrixCourse {...this.props}/>;


        return <div style={{padding: '20px 30px'}}>
            <ContentFormatted html={course.description}/>

            <LocationMatrixButton {...this.props} />

            <table style={{border: 'none', margin: '0'}}>
                <thead style={{border: 'none'}}>
                <tr style={{background: 'white'}}>
                    <Th>Tilgjengelige kurssteder</Th>
                    {Object.keys(course.weeks).map(weekId => {
                        if (!this.getFilters().isWeekShown(weekId))
                            return null;

                        const week = course.weeks[weekId];
                        return <Th key={weekId}>Uke {week.week}</Th>;
                    })}
                </tr>
                </thead>
                <tbody style={{border: 'none'}}>
                {Object.keys(course.matrix).map(locationId => {
                    const location = catalog.locations[locationId];

                    let show = false;
                    const locationHtml = <tr key={locationId} style={{background: 'white', color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#3f3f3d'}}>
                        <Td><LocationName location={location} {...this.props}/></Td>
                        {Object.keys(course.weeks).map(weekId => {
                            if (!this.getFilters().isWeekShown(weekId))
                                return null;

                            //const week = course.weeks[weekId];
                            return <Td key={weekId}>
                                {course.matrix[locationId][weekId]
                                    ? Object.keys(course.matrix[locationId][weekId]).map(activityId => {
                                        const activity = course.matrix[locationId][weekId][activityId];

                                        if ((this.props.selectedArea !== AREAS.DEFAULT && this.props.selectedArea !== activity.location.areaId)) {
                                            return null;
                                        }

                                        show = show || !(
                                            this.props.showAvailableCourse && (
                                                activity.bookingDeadlineExpired
                                                || activity.ended
                                                || (activity.isWaitingListEnabled && activity.numberOfVacantSlots <= 0)
                                            )
                                        );
                                        return <ActivityApplyAction
                                            key={activityId}
                                            activity={activity}
                                            {...this.props}
                                        />;
                                    })
                                    : null}
                            </Td>;
                        })}
                    </tr>;

                    return show ? locationHtml : null;
                })}
                </tbody>
            </table>
        </div>;
    }

    getFilters(): CourseCatalogFilters {
        return this.props.catalogFilters;
    }
}

export class LocationName extends React.Component {
    state = {modal: null};

    render() {
        const location = this.props.location;

        if (!location)
            return <div/>;

        return <div style={{display: 'flex', alignItems: 'center', fontSize: '15px'}}>
            {location.coordinate
                ? <div>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            this.setState({
                                modal: <LocationModal {...this.props} location={location} onRequestClose={() => {
                                    this.setState({modal: null});
                                }}/>
                            });
                        }}
                        style={{margin: '-12px', marginRight: '0'}}
                    >
                        <Icon style={{color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#57585a'}}>location_on</Icon>
                    </IconButton>
                </div>
                : null}
            <div>{location.name}</div>
            {this.state.modal}
        </div>;
    }
}

export class LocationMatrixButton extends React.Component {
    state = {modal: null};

    render() {
        return <div>
            <Button raised color={THEME.USE_V2 ? 'primary' : 'accent'} onClick={() => {
                this.setState({
                    modal: <LocationMatrixModal {...this.props} onRequestClose={() => {
                        this.setState({modal: null});
                    }}/>
                })
            }}>
                Vis på kart
            </Button>
            {this.state.modal}
        </div>;
    }
}

class LocationMatrixModal extends React.Component {
    static instance;

    render() {
        return <Dialog className={'LMMModal'} onClose={this.props.onRequestClose} open maxWidth={false}>
            <DialogContent style={{padding: '0'}}>
                <div id={'LocationMap'} style={{
                    width: Math.round($(window).width() * 0.9) + 'px',
                    height: Math.round($(window).height() * 0.9 - 60) + 'px'
                }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onRequestClose}>Lukk</Button>
            </DialogActions>
        </Dialog>;
    }

    componentDidMount() {
        LocationMatrixModal.instance = this;

        const modalId = 'LMM' + Math.round(Math.random() * 1000000);

        const infoWindows = {};

        const map = $('#LocationMap')
            .gmap3({
                center: [59.8937803, 10.6446932],
                zoom: 11
            })
            .marker(Object.keys(this.props.course.matrix).map((locationId) => {
                const location = this.props.catalog.locations[locationId];

                if (!location.coordinate)
                    return;

                return {position: location.coordinate, locationId};
            }))
            .on('click', function (marker) {
                infoWindows[marker.locationId].open(marker.map);
            });


        const _this = this;
        Object.keys(this.props.course.matrix).forEach(locationId => {
            const location = this.props.catalog.locations[locationId];

            if (!location.coordinate)
                return;

            map
                .infowindow({
                    position: [location.coordinate.lat, location.coordinate.lng],
                    content: '<div id="' + modalId + '-' + locationId + '"><div style="width:200px;height:100px;"></div></div>'
                })
                .then(function (infowindow) {
                    if ($(window).width() > 767) {
                        infowindow.open(this.get(0));
                    }

                    const fn = () => {

                        let element = document.getElementById(modalId + '-' + locationId);
                        if (element) {
                            ReactDOM.render(
                                <LocationMatrixInfowindow {..._this.props} location={location}/>,
                                element
                            );
                            return true;
                        }
                        return false;
                    };

                    let timer = null;

                    timer = () => {
                        if (!fn())
                            setTimeout(timer, 100);
                    };

                    timer();
                    infoWindows[locationId] = infowindow;
                });

        });
    }
}

export class LocationMatrixInfowindow extends React.Component {
    static instances = [];

    render() {
        const course = this.props.course;
        const location = this.props.location;
        const locationId = location.id;

        return <div style={{width: '200px'}}>
            <div>{location.name}</div>
            <div>{location.address}</div>
            {course ? Object.keys(course.weeks).map(weekId => {

                if (!course.matrix[locationId][weekId] || Object.keys(course.matrix[locationId][weekId]).length === 0)
                    return null;

                const week = course.weeks[weekId];
                return <div key={weekId}
                            style={{width: '100%', padding: '5px 0', display: 'flex', alignItems: 'center'}}>
                    <div style={{
                        fontWeight: '600',
                        padding: '5px 0',
                        width: '60px',
                    }}>Uke {week.week}</div>
                    <div style={{flex: '1', display: 'flex', flexWrap: 'wrap'}}>
                        {course.matrix[locationId][weekId]
                            ? Object.keys(course.matrix[locationId][weekId]).map(activityId => {
                                const activity = course.matrix[locationId][weekId][activityId];
                                return <div
                                    style={{padding: '0 5px', display: 'flex', justifyContent: 'center'}}>
                                    <ActivityApplyAction
                                        key={activityId}
                                        activity={activity}
                                        {...this.props}
                                    />
                                </div>;
                            })
                            : null}
                    </div>
                </div>;
            }) : null}
        </div>;
    }

    componentDidMount() {
        LocationMatrixInfowindow.instances.push(this);
    }

    componentWillUnmount() {
        LocationMatrixInfowindow.instances.splice(LocationMatrixInfowindow.instances.indexOf(this), 1);
    }
}