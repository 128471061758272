import * as React from "react";
import SummerSchoolThemeProvider from "./SummerSchoolThemeProvider";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton} from "material-ui";
import CustomFieldsEditor from "./CustomFieldsEditor";
import JsonApi from "./JsonApi";
import { THEME } from "./Constants";

export default class CustomFieldsAnswersWrapper extends React.Component {
    state = {expanded: false};

    render() {
        return <SummerSchoolThemeProvider>
            <div style={{marginBottom: '40px'}}>

                <div style={{display: 'flex', cursor: 'pointer', alignItems: 'center', marginBottom: '14px'}}
                    id="custom-fields-answers-header"
                    aria-controls="custom-fields-answers-content"
                    aria-expanded={this.state.expanded}
                    role="button"
                    onClick={() => {
                        this.setState({expanded: !this.state.expanded});
                    }}
                    onKeyUp={(event) => enterKeyHandle(event)}
                    tabIndex={0}
                >
                    <div style={{fontWeight: '600', textDecoration: 'underline'}}>
                        Dine svar:
                    </div>
                    <div style={{paddingLeft: '10px'}}>
                        <Icon>{this.state.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                    </div>
                </div>

                {this.state.expanded ? <CustomFieldsAnswers {...this.props}/> : null}
            </div>
        </SummerSchoolThemeProvider>;
    }
}

class CustomFieldsAnswers extends React.Component {
    state = {modal: null};

    render() {
        return <div style={{background: THEME.USE_V2 ? THEME.SECONDARY_COLOR : 'rgba(0,98,117,0.1)', padding: '10px', fontSize: '15px'}}
            id="custom-fields-answers-content"
            aria-labelledby="custom-fields-answers-header"
        >
            <div style={{display: 'flex', alignItems: 'center', padding: '10px', paddingBottom: '5px'}}>
                <div style={{
                    fontSize: '18px',
                    color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#3d3d3f',
                    fontWeight: 700
                }}>Informasjon om {this.props.child.firstname}</div>
                {this.props.canEdit
                    ? <div><IconButton style={{margin: '-14px 0'}} onClick={() => {
                        this.setState({
                            modal: <CustomFieldsAnswerEditorWrapper
                                {...this.props}
                                {...this.state.fieldsData}
                                handleRequestClose={() => this.setState({modal: null})}
                                setData={(data) => {
                                    this.data = data;
                                    this.dataArrays = {};

                                    this.state.fieldsData.data = data;
                                    Object.keys(data).forEach(key => {
                                        this.state.fieldsData.dataArrays[key] = [data[key]];
                                    });

                                    this.setState({
                                        modal: null,
                                        fieldsData: this.state.fieldsData
                                    });
                                }}
                            />
                        });
                    }}>
                        <Icon style={{color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#444'}}>edit</Icon>
                    </IconButton></div>
                    : null}
            </div>

            <div>
                {this.props.fields.map(field => {
                    let dataArray = this.state.fieldsData.dataArrays[field.customField._id];

                    if (!dataArray)
                        dataArray = [];

                    let value = 'Ikke satt';

                    if (dataArray.length > 0) {
                        value = dataArray.map(v => {
                            if (v === true)
                                return 'Ja';
                            else if (v === false)
                                return 'Nei';
                            else
                                return v;
                        }).join(', ');
                    }

                    return <div key={field.customField._id} style={{padding: '7px 10px'}}>
                        <div style={{fontWeight: '600'}}>{field.customField.name}</div>
                        <div style={{wordWrap: 'break-word'}}>{value}</div>

                        {dataArray[0] === true && field.fieldType.options.trueDescription
                            ? <div className={'content'}
                                   dangerouslySetInnerHTML={{__html: field.fieldType.options.trueDescription}}
                                   style={{margin: '10px 0'}}
                            />
                            : null}
                        {dataArray[0] === false && field.fieldType.options.falseDescription
                            ? <div className={'content'}
                                   dangerouslySetInnerHTML={{__html: field.fieldType.options.falseDescription}}
                                   style={{margin: '10px 0'}}
                            />
                            : null}

                    </div>;
                })}
            </div>

            {this.state.modal}

        </div>
    }

    componentWillMount() {
        this.setState({
            fieldsData: {
                data: this.props.data,
                dataArrays: this.props.dataArrays,
                fields: this.props.fields,
            }
        });
    }
}

class CustomFieldsAnswerEditorWrapper extends React.Component {
    state = {passed: false};

    render() {
        const onSubmit = () => {
            JsonApi.post('/api/custom-fields/save-answers', {
                enrollmentGroupId: this.props.enrollmentGroup._id,
                childId: this.props.child.id,
                customFields: {form: this.customFieldsEditor.getSerializedData()}
            }, async (response) => {
                this.props.setData(response);
            });
        };

        return <Dialog open onClose={this.props.handleRequestClose}>
            <DialogTitle>Rediger informasjon om {this.props.child.firstname}</DialogTitle>
            <DialogContent>
                <CustomFieldsEditor
                    data={this.props}
                    ref={ref => {
                        this.customFieldsEditor = ref;
                        if (ref && this.state.passed !== ref.isValidationPassed()) {
                            this.setState({passed: ref.isValidationPassed()});
                            console.log(ref.isValidationPassed());
                        }
                    }}
                    onChange={() => {
                        this.setState({passed: this.customFieldsEditor.isValidationPassed()});
                    }}
                    onSubmit={() => onSubmit()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.handleRequestClose}>Avbryt</Button>
                <Button onClick={() => onSubmit()} color={'primary'}
                        disabled={!this.state.passed}>Lagre</Button>
            </DialogActions>
        </Dialog>;
    }
}