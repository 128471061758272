import * as React from "react";
import styled from 'styled-components';
import {Button} from "material-ui";
import ContentFormatted from "../common/ContentFormatted";
import { THEME } from "../common/Constants";

export default class NonFeaturedEnrollmentGroups extends React.Component {
    render() {
        const Div = styled.div`
            width: 33%;        
            
            @media all and (max-width: 600px){
                width:100%;
            }
        `;

        const content = <div>
            <ContentFormatted html={this.props.data.description}/>

            <div style={{marginLeft: '-16px'}}>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        marginTop: '25px',
                    }}
                >
                    {this.props.data.buttons.map(enrollmentGroup => {
                        return <Div key={enrollmentGroup.id}>
                            <Button
                                href={enrollmentGroup.href}
                                color={'primary'}
                                style={{fontWeight: '600', minWidth: 'auto', color: THEME.USE_V2 ? THEME.PRIMARY_COLOR : '#006275', fontSize: '14px'}}
                            >
                                {enrollmentGroup.name}
                            </Button>
                        </Div>;
                    })}
                </div>
            </div>
        </div>

        return THEME.USE_V2 ? <SummerSchoolThemeProvider children={content}/> : content;
    }
}