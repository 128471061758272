import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "material-ui";
import JsonApi from "../common/JsonApi";
import SubgroupSelector from "../common/SubgroupSelector";
import BirthdateField from "../common/BirthdateField";
import GenderField from "../common/GenderField";
import { THEME } from '../common/Constants';
import SummerSchoolThemeProvider from '../common/SummerSchoolThemeProvider';

export default class EditStudentModal extends React.Component {
    state = {
        response: null,
        showOtherSchoolField: false,
        errors: []
    };

    saving = false;

    render() {
        if (this.state.response === null)
            return <div/>;

        const dialog = <Dialog onClose={() => {
            this.props.handleRequestClose();
        }} open>
            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }} style={{margin: '0'}}>
                <DialogTitle>{this.state.response.title}</DialogTitle>
                <DialogContent style={{maxWidth: '350px'}}>
                    <TextField
                        label="Fornavn"
                        id='firstname'
                        InputLabelProps={{ htmlFor: 'firstname' }}
                        fullWidth
                        defaultValue={this.state.response.user.firstname}
                        disabled={!this.state.response.permissions.edit_name}
                        error={this.state.errors.indexOf('firstname') !== -1}
                        helperText={this.state.errors.indexOf('firstname') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({firstname: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="Etternavn"
                        id='surname'
                        InputLabelProps={{ htmlFor: 'surname' }}
                        fullWidth
                        defaultValue={this.state.response.user.surname}
                        disabled={!this.state.response.permissions.edit_name}
                        error={this.state.errors.indexOf('surname') !== -1}
                        helperText={this.state.errors.indexOf('surname') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({surname: event.target.value});
                        }}
                        margin="normal"
                    />

                    <SubgroupSelector
                        disabled={!this.state.response.permissions.edit_student_role}
                        error={this.state.errors.indexOf('student_role') !== -1}
                        helperText={this.state.errors.indexOf('student_role') !== -1 ? 'Påkrevd' : ''}
                        defaultSubgroupId={this.state.response.studentRole ? this.state.response.studentRole.subgroup.id : null}
                        defaultSchoolId={this.state.response.studentRole ? this.state.response.studentRole.school.id : null}
                        onChange={(subgroupId) => {
                            this.setState({subgroupId});
                        }}
                        onChangeShowOtherSchoolField={(showOtherSchoolField) => this.setState({showOtherSchoolField})}
                    />

                    {this.state.showOtherSchoolField
                        ? <TextField
                            label="Hvilken skole"
                            id='otherSchoolName'
                            InputLabelProps={{ htmlFor: 'otherSchoolName' }}
                            fullWidth
                            defaultValue={this.state.response.studentRole.otherSchool}
                            disabled={!this.state.response.permissions.edit_student_role}
                            error={this.state.errors.indexOf('otherSchoolName') !== -1}
                            helperText={this.state.errors.indexOf('otherSchoolName') !== -1 ? 'Påkrevd' : ''}
                            onChange={(event) => {
                                this.setState({otherSchoolName: event.target.value});
                            }}
                            margin="normal"
                        />
                        : null}

                    <BirthdateField
                        disabled={!this.state.response.permissions.edit_birthdate}
                        error={this.state.errors.indexOf('birthdate') !== -1}
                        helperText={this.state.errors.indexOf('birthdate') !== -1 ? 'Påkrevd' : ''}
                        defaultValue={this.state.response.user.birthdate}
                        showFieldLabel={true}
                        onChange={(birthdate) => {
                            this.setState({birthdate});
                        }}
                    />

                    <TextField
                        label="Mobil"
                        id='phoneNumber'
                        InputLabelProps={{ htmlFor: 'phoneNumber' }}
                        fullWidth
                        defaultValue={this.state.response.user.phoneNumber}
                        disabled={!this.state.response.permissions.edit_phoneNumber}
                        error={this.state.errors.indexOf('phoneNumber') !== -1}
                        helperText={this.state.errors.indexOf('phoneNumber') !== -1 ? 'Påkrevd' : 'Under 13 år? Bruk foresattes mobil'}
                        onChange={(event) => {
                            this.setState({phoneNumber: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="E-post"
                        id='email'
                        InputLabelProps={{ htmlFor: 'email' }}
                        fullWidth
                        defaultValue={this.state.response.user.email}
                        disabled={!this.state.response.permissions.edit_email}
                        error={this.state.errors.indexOf('email') !== -1}
                        helperText={this.state.errors.indexOf('email') !== -1 ? 'Påkrevd' : 'Under 13 år ? Bruk foresattes epost'}
                        onChange={(event) => {
                            this.setState({email: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="Gateadresse"
                        id='address'
                        InputLabelProps={{ htmlFor: 'address' }}
                        fullWidth
                        defaultValue={this.state.response.user.address}
                        disabled={!this.state.response.permissions.edit_address}
                        error={this.state.errors.indexOf('address') !== -1}
                        helperText={this.state.errors.indexOf('address') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({address: event.target.value});
                        }}
                        margin="normal"
                    />
                    <div style={{display: 'flex'}}>
                        <div style={{flex: '1', paddingRight: '20px'}}>
                            <TextField
                                label="Postnummer"
                                id='postalCode'
                                InputLabelProps={{ htmlFor: 'postalCode' }}
                                fullWidth
                                defaultValue={this.state.response.user.postalCode}
                                disabled={!this.state.response.permissions.edit_address}
                                error={this.state.errors.indexOf('postalCode') !== -1}
                                helperText={this.state.errors.indexOf('postalCode') !== -1 ? 'Påkrevd' : ''}
                                onChange={(event) => {
                                    this.setState({postalCode: event.target.value});
                                }}
                                margin="normal"
                            />
                        </div>
                        <div style={{flex: '2'}}>
                            <TextField
                                label="Poststed"
                                id='postalName'
                                InputLabelProps={{ htmlFor: 'postalName' }}
                                fullWidth
                                defaultValue={this.state.response.user.postalName}
                                disabled={!this.state.response.permissions.edit_address}
                                error={this.state.errors.indexOf('postalName') !== -1}
                                helperText={this.state.errors.indexOf('postalName') !== -1 ? 'Påkrevd' : ''}
                                onChange={(event) => {
                                    this.setState({postalName: event.target.value});
                                }}
                                margin="normal"
                            />
                        </div>
                    </div>


                    <GenderField
                        error={this.state.errors.indexOf('gender') !== -1}
                        helperText={this.state.errors.indexOf('gender') !== -1 ? 'Påkrevd' : ''}
                        disabled={!this.state.response.permissions.edit_gender}
                        defaultValue={this.state.response.user.gender}
                        onChange={(gender) => {
                            this.setState({gender});
                        }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.props.handleRequestClose();
                    }} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={() => {
                        this.save()
                    }} color="primary">
                        Lagre
                    </Button>
                </DialogActions>
                <input type={'submit'} style={{position: 'absolute', left: '-9999px'}} value='Submit'/>
            </form>
            {this.state.modal}
        </Dialog>;

        return THEME.USE_V2 ? <SummerSchoolThemeProvider children={dialog}/> : dialog;
    }

    componentDidMount() {
        JsonApi.post('/api/modals/edit_student/get_edit_student_modal_data', {
            userId: this.props.userId,
            userRoleId: this.props.userRoleId
        }, (response) => {
            this.setState({response}, () => {
                if (this.state.response.studentRole.otherSchool) {
                    this.setState({
                        showOtherSchoolField: true,
                        otherSchoolName: this.state.response.studentRole.otherSchool
                    })
                }
            });
        })
    }

    save() {
        let errors = [];

        if (this.state.response.permissions.edit_name) {
            if ((this.state.firstname === undefined && !this.state.response.user.firstname) || (this.state.firstname !== undefined && this.state.firstname.length === 0)) {
                errors.push('firstname');
            }
            if ((this.state.surname === undefined && !this.state.response.user.surname) || (this.state.surname !== undefined && this.state.surname.length === 0)) {
                errors.push('surname');
            }
        }

        if (this.state.response.permissions.edit_phoneNumber) {
            if ((this.state.phoneNumber === undefined && !this.state.response.user.phoneNumber) || (this.state.phoneNumber !== undefined && this.state.phoneNumber.length < 8)) {
                errors.push('phoneNumber');
            }
        }

        // if (this.state.response.permissions.edit_email) {
        //     if ((this.state.email === undefined && !this.state.response.user.email) || (this.state.email !== undefined && this.state.email.length === 0)) {
        //         errors.push('email');
        //     }
        // }


        if (this.state.response.permissions.edit_birthdate) {
            if ((this.state.birthdate === undefined && !this.state.response.user.birthdate) || (this.state.birthdate !== undefined && !this.state.birthdate)) {
                errors.push('birthdate');
            }
        }
        if (this.state.response.permissions.edit_student_role) {
            if ((this.state.subgroupId === undefined && !this.state.response.studentRole) || (this.state.subgroupId !== undefined && !this.state.subgroupId)) {
                errors.push('student_role');
            }

            if (this.state.showOtherSchoolField && !this.state.otherSchoolName) {
                errors.push('otherSchoolName');
            }
        }

        if (this.state.response.permissions.edit_address) {
            if ((this.state.address === undefined && !this.state.response.user.address) || (this.state.address !== undefined && this.state.address.length === 0)) {
                errors.push('address');
            }
            if ((this.state.postalCode === undefined && !this.state.response.user.postalCode) || (this.state.postalCode !== undefined && this.state.postalCode.length === 0)) {
                errors.push('postalCode');
            }
            if ((this.state.postalName === undefined && !this.state.response.user.postalName) || (this.state.postalName !== undefined && this.state.postalName.length === 0)) {
                errors.push('postalName');
            }
        }
        if (this.state.response.child && this.state.response.permissions.edit_relation) {
            if ((this.state.relation === undefined && !this.state.response.child.relation) || (this.state.relation !== undefined && this.state.relation.length === 0)) {
                errors.push('relation');
            }
        }

        this.setState({errors});
        if (errors.length > 0 || this.saving) {
            return;
        }

        this.saving = true;

        JsonApi.post('/api/modals/edit_student/process_edit_student_modal', {
            userId: this.props.userId,
            userRoleId: this.props.userRoleId,
            firstname: this.state.firstname,
            surname: this.state.surname,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            address: this.state.address,
            postalCode: this.state.postalCode,
            birthdate: this.state.birthdate,
            subgroupId: this.state.subgroupId,
            relation: this.state.relation,
            postalName: this.state.postalName,
            notificationsEnabled: this.state.notificationsEnabled,
            gender: this.state.gender,
            otherSchoolName: this.state.otherSchoolName
        }, (response) => {
            this.saving = false;
            if (response.errors) {
                this.setState({
                    modal: <Dialog open onClose={() => {
                        this.setState({modal: null});
                    }}>
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Feil"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <ul>
                                    {response.errors.map(error => {
                                        return <li>{error}</li>;
                                    })}
                                </ul>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({modal: null});
                            }} color="primary">
                                Lukk
                            </Button>
                        </DialogActions>
                    </Dialog>
                });
            } else if (response.success) {
                this.props.handleRequestDone({
                    user: {
                        firstname: this.state.firstname ? this.state.firstname : this.state.response.user.firstname,
                    }
                });
                location.reload();
            }
        })
    }
}