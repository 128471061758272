import * as React from "react";
import {FormControl, FormHelperText, Input, InputLabel, MenuItem, Select, TextField} from "material-ui";
import JsonApi from "./JsonApi";

export default class SubgroupSelector extends React.Component {
    state = {schoolId: null, subgroupId: null, otherSchoolId: null};

    render() {
        if (this.state.schoolId === null)
            this.state.schoolId = this.props.defaultSchoolId ? this.props.defaultSchoolId : '';
        if (this.state.subgroupId === null)
            this.state.subgroupId = this.props.defaultSubgroupId ? this.props.defaultSubgroupId : '';

        const {showOtherSchoolField} = this.state;

        return <div style={{display: 'flex', marginTop: '16px', marginBottom: '8px'}}>
                <div style={{flex: '1', paddingRight: '10px'}}>
                    <FormControl fullWidth disabled={this.props.disabled} error={this.props.error}>
                        <InputLabel>Skole</InputLabel>
                        <Select
                            native
                            value={this.state.schoolId}
                            onChange={(event) => {
                                this.setState({schoolId: event.target.value, subgroupId: ''});
                                this.onChangeOtherSchool(event.target.value);
                                this.props.onChange(null);
                            }}
                            input={<Input/>}
                        >
                            <option value=""/>
                            {this.state.schools
                                ? this.state.schools.map(school => {
                                    if (school.public === false && school.id !== this.state.schoolId)
                                        return;

                                    return <option key={school.id} value={school.id}>
                                        {school.name}
                                    </option>;
                                })
                                : null}
                        </Select>
                        {this.props.helperText ? <FormHelperText error>{this.props.helperText}</FormHelperText> : null}
                    </FormControl>
                </div>
                <div style={{flex: '1', paddingLeft: '10px'}}>
                    <FormControl fullWidth disabled={this.props.disabled} error={this.props.error}>
                        <InputLabel>Klasse</InputLabel>
                        <Select
                            native
                            value={this.state.subgroupId}
                            onChange={(event) => {
                                this.setState({subgroupId: event.target.value});
                                this.props.onChange(event.target.value ? event.target.value : null);
                            }}
                            input={<Input/>}
                        >
                            <option value=""/>
                            {this.state.subgroups
                                ? this.state.subgroups.map(subgroup => {
                                    return <option key={subgroup.id} value={subgroup.id}>
                                        {subgroup.name}
                                    </option>;
                                })
                                : null}
                        </Select>
                        {this.props.helperText ? <FormHelperText error>{this.props.helperText}</FormHelperText> : null}
                    </FormControl>
                </div>
            </div>
    }

    componentDidMount() {
        JsonApi.post('/api/subgroup-selector/get-schools', {}, (schools) => {
            this.setState({schools}, () => {
                this.setOtherSchool();
            });

        });
        this.loadSubgroups();
    }

    onChangeOtherSchool(schoolId) {
        if (schoolId == this.state.otherSchoolId) {
            this.props.onChangeShowOtherSchoolField(true);
        } else {
            this.props.onChangeShowOtherSchoolField(false);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.schoolId !== prevState.schoolId)
            this.loadSubgroups();
    }

    setOtherSchool() {
        const {schools} = this.state;
        if (!schools)
            return;

        let otherSchool = schools.filter(school => school.isOtherSchool === true);
        if (otherSchool && otherSchool.length > 0) {
            this.setState({otherSchoolId: otherSchool[0].id})
        }
    }

    loadSubgroups() {
        JsonApi.post('/api/subgroup-selector/get-subgroups', {
            schoolId: this.state.schoolId,
            shouldAddCurrentAcademicYearToClassLabel: true
        }, (subgroups) => {
            this.setState({subgroups});
        });
    }
}