import * as React from "react";
import {Button, Icon} from "material-ui";
import SummerSchoolThemeProvider from "../common/SummerSchoolThemeProvider";
import ContentFormatted from "../common/ContentFormatted";
import { THEME } from "../common/Constants";

export default class FeaturedEnrollmentGroupsActions extends React.Component {
    state = {expanded: false};

    render() {
        const data = this.props.data;
        const enrollmentGroupId = data && data.enrollmentGroup && data.enrollmentGroup.id
            ? data.enrollmentGroup.id
            : '';

        return <SummerSchoolThemeProvider>
            <div>
                <div style={{display: 'flex', alignItems: 'flex-end'}}>
                    <div style={{flex: '2', minWidth: '160px'}}>
                        <div style={{display: 'flex', cursor: 'pointer', alignItems: 'center'}}
                            id={`enrollment-group-detail-header-${enrollmentGroupId}`}
                            aria-controls={`enrollment-group-detail-content-${enrollmentGroupId}`}
                            aria-expanded={this.state.expanded}
                            role="button"
                            onClick={() => {
                                this.setState({expanded: !this.state.expanded});
                            }}
                            onKeyUp={(event) => enterKeyHandle(event)}
                            tabIndex={0}
                        >
                            <div style={{
                                fontWeight: '700',
                                textDecoration: 'underline',
                                color: THEME.USE_V2 ? THEME.PRIMARY_COLOR : '#006275',
                                fontSize: '15px'
                            }}>
                                Slik fungerer det
                            </div>
                            <div className="btn-toggle" style={{paddingLeft: '5px'}}>
                                <Icon>{this.state.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</Icon>
                                {/*<Icon>{this.state.expanded ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>*/}
                            </div>
                        </div>
                    </div>
                    <Button
                        href={data.href}
                        raised
                        color={'primary'}
                        style={{
                            flex: '1',
                            maxWidth: '200px',
                            height: '40px',
                            color: '#FFF',
                            fontSize: '14px',
                            fontWeight: '300'
                        }}
                    >{data.buttonTitle}</Button>
                </div>
                {this.state.expanded
                    ? <div
                        id={`enrollment-group-detail-content-${enrollmentGroupId}`}
                        aria-labelledby={`enrollment-group-detail-header-${enrollmentGroupId}`}
                    >
                        <ContentFormatted html={data.description}/>
                    </div>
                    : null}
            </div>
        </SummerSchoolThemeProvider>
    }
}