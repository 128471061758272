import * as React from 'react';
import $ from 'jquery'
import ReactDOM from 'react-dom';
import JsonApi from "./JsonApi";
import AuthenticationModal from "../modals/AuthenticationModal";
import SummerSchoolThemeProvider from "./SummerSchoolThemeProvider";

export default class UserSession {
    static data;

    static async updateData() {
        return new Promise(solve => {
            JsonApi.jsonp('/api/user-session/get-session-data', {}, (res) => {
                UserSession.data = res;
                solve();
            });
        });
    }

    static isAuthenticated() {
        return !!UserSession.data && !!UserSession.data.user;
    }

    static openAuthenticationModal( options) {
        const id = 'AuthenticationWindow-' + Math.round(Math.random() * 1000000);

        $('body').append('<div id="' + id + '"></div>');
        ReactDOM.render(
            <SummerSchoolThemeProvider>
                <AuthenticationModal {...options}/>
            </SummerSchoolThemeProvider>,
            document.getElementById(id)
        );
    }
}