import * as React from 'react';
import {
    Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    FormControlLabel, FormHelperText, TextField
} from "material-ui";
import red from 'material-ui/colors/red';
import JsonApi from "../common/JsonApi";
import SubgroupSelector from "../common/SubgroupSelector";
import GenderField from "../common/GenderField";
import BirthdateField from "../common/BirthdateField";

export default class CompleteProfileModal_Student extends React.Component {
    state = {
        errors: [],
        accepted: false,
        response: null,
        modal: null,
    };

    render() {
        if (this.state.response === null)
            return <div/>;

        return <Dialog open>
            <form onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.save();
            }} style={{margin: '0'}}>
                <DialogTitle>Registrer profil</DialogTitle>
                <DialogContent style={{maxWidth: '350px'}}>
                    <TextField
                        label="Fornavn"
                        fullWidth
                        defaultValue={this.state.response.user.firstname}
                        disabled={!this.state.response.permissions.edit_name}
                        error={this.state.errors.indexOf('firstname') !== -1}
                        helperText={this.state.errors.indexOf('firstname') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({firstname: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="Etternavn"
                        fullWidth
                        defaultValue={this.state.response.user.surname}
                        disabled={!this.state.response.permissions.edit_name}
                        error={this.state.errors.indexOf('surname') !== -1}
                        helperText={this.state.errors.indexOf('surname') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({surname: event.target.value});
                        }}
                        margin="normal"
                    />

                    <SubgroupSelector
                        disabled={!this.state.response.permissions.edit_student_role}
                        defaultSubgroupId={this.state.response.studentRole ? this.state.response.studentRole.subgroup.id : null}
                        defaultSchoolId={this.state.response.studentRole ? this.state.response.studentRole.school.id : null}
                        error={this.state.errors.indexOf('studentRole') !== -1}
                        helperText={this.state.errors.indexOf('studentRole') !== -1 ? 'Påkrevd' : ''}
                        onChange={(subgroupId) => {
                            this.setState({subgroupId});
                        }}
                    />

                    <BirthdateField
                        disabled={!this.state.response.permissions.edit_birthdate}
                        defaultValue={this.state.response.user.birthdate}
                        error={this.state.errors.indexOf('birthdate') !== -1}
                        helperText={this.state.errors.indexOf('birthdate') !== -1 ? 'Påkrevd' : ''}
                        onChange={(birthdate) => {
                            this.setState({birthdate});
                        }}
                    />

                    <TextField
                        label="Mobil"
                        fullWidth
                        defaultValue={this.state.response.user.phoneNumber}
                        disabled={!this.state.response.permissions.edit_phoneNumber}
                        error={this.state.errors.indexOf('phoneNumber') !== -1}
                        helperText={this.state.errors.indexOf('phoneNumber') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({phoneNumber: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="E-post"
                        fullWidth
                        defaultValue={this.state.response.user.email}
                        disabled={!this.state.response.permissions.edit_email}
                        error={this.state.errors.indexOf('email') !== -1}
                        helperText={this.state.errors.indexOf('email') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({email: event.target.value});
                        }}
                        margin="normal"
                    />
                    <TextField
                        label="Gateadresse"
                        fullWidth
                        defaultValue={this.state.response.user.address}
                        disabled={!this.state.response.permissions.edit_address}
                        error={this.state.errors.indexOf('address') !== -1}
                        helperText={this.state.errors.indexOf('address') !== -1 ? 'Påkrevd' : ''}
                        onChange={(event) => {
                            this.setState({address: event.target.value});
                        }}
                        margin="normal"
                    />
                    <div style={{display: 'flex'}}>
                        <div style={{flex: '1', paddingRight: '20px'}}>
                            <TextField
                                label="Postnummer"
                                fullWidth
                                defaultValue={this.state.response.user.postalCode}
                                disabled={!this.state.response.permissions.edit_address}
                                error={this.state.errors.indexOf('postalCode') !== -1}
                                helperText={this.state.errors.indexOf('postalCode') !== -1 ? 'Påkrevd' : ''}
                                onChange={(event) => {
                                    this.setState({postalCode: event.target.value});
                                }}
                                margin="normal"
                            />
                        </div>
                        <div style={{flex: '2'}}>
                            <TextField
                                label="Poststed"
                                fullWidth
                                defaultValue={this.state.response.user.postalName}
                                disabled={!this.state.response.permissions.edit_address}
                                error={this.state.errors.indexOf('postalName') !== -1}
                                helperText={this.state.errors.indexOf('postalName') !== -1 ? 'Påkrevd' : ''}
                                onChange={(event) => {
                                    this.setState({postalName: event.target.value});
                                }}
                                margin="normal"
                            />
                        </div>
                    </div>


                    <GenderField
                        error={this.state.errors.indexOf('gender') !== -1}
                        helperText={this.state.errors.indexOf('gender') !== -1 ? 'Påkrevd' : ''}
                        disabled={!this.state.response.permissions.edit_gender}
                        defaultValue={this.state.response.user.gender}
                        onChange={(gender) => {
                            this.setState({gender});
                        }}
                    />

                    <FormControl error={this.state.errors.indexOf('accepted') !== -1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.accepted}
                                    onChange={(event, accepted) => {
                                        this.setState({accepted});
                                    }}
                                    style={this.state.errors.indexOf('accepted') !== -1 ? {color: red[500]} : {}}
                                />
                            }
                            label={<span style={this.state.errors.indexOf('accepted') !== -1 ? {color: red[500]} : {}}>Jeg har lest og godtar <a
                                href={this.state.response.privacyDeclarationLink} target="_blank">personvernerklæringen.</a></span>}
                        />
                        {this.state.errors.indexOf('accepted') !== -1 ? <FormHelperText>Påkrevd</FormHelperText> : null}
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button href={this.state.response.signOutUrl}>
                        Logg ut
                    </Button>
                    <Button onClick={() => {
                        this.save()
                    }} color="primary">
                        Lagre
                    </Button>
                </DialogActions>
                <input type={'submit'} style={{position: 'absolute', left: '-9999px'}}/>
            </form>
            {this.state.modal}
        </Dialog>
            ;
    }

    componentDidMount() {
        JsonApi.post('/api/modals/complete_profile_student/get_complete_profile_student_modal_data', {userId: this.props.userId}, (response) => {
            this.setState({response});
        })
    }

    save() {
        if (this.updateValidation().length > 0 || this.saving)
            return;

        this.saving = true;

        JsonApi.post('/api/modals/complete_profile_student/process_complete_profile_student_modal', {
            userId: this.props.userId,
            subgroupId: this.state.subgroupId,
            firstname: this.state.firstname,
            surname: this.state.surname,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            gender: this.state.gender,
            birthdate: this.state.birthdate,
            address: this.state.address,
            postalCode: this.state.postalCode,
            postalName: this.state.postalName,
        }, (response) => {
            this.saving = false;
            if (response.errors) {
                this.setState({
                    modal: <Dialog open onClose={() => {
                        this.setState({modal: null});
                    }}>
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Feil"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <ul>
                                    {response.errors.map(error => {
                                        return <li>{error}</li>;
                                    })}
                                </ul>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.setState({modal: null});
                            }} color="primary">
                                Lukk
                            </Button>
                        </DialogActions>
                    </Dialog>
                });
            } else if (response.success) {
                this.props.handleRequestDone({
                    user: {
                        firstname: this.state.firstname ? this.state.firstname : this.state.response.user.firstname,
                    }
                });
                location.reload();
            }
        })
    }

    updateValidation() {

        let errors = [];

        if (this.state.response.permissions.edit_name) {
            if ((this.state.firstname === undefined && !this.state.response.user.firstname) || (this.state.firstname !== undefined && this.state.firstname.length === 0)) {
                errors.push('firstname');
            }
            if ((this.state.surname === undefined && !this.state.response.user.surname) || (this.state.surname !== undefined && this.state.surname.length === 0)) {
                errors.push('surname');
            }
        }

        if (this.state.response.permissions.edit_phoneNumber) {
            if ((this.state.phoneNumber === undefined && !this.state.response.user.phoneNumber) || (this.state.phoneNumber !== undefined && this.state.phoneNumber.length < 8)) {
                errors.push('phoneNumber');
            }
        }
        if (this.state.response.permissions.edit_birthdate) {
            if ((this.state.birthdate === undefined && !this.state.response.user.birthdate) || (this.state.birthdate !== undefined && !this.state.birthdate)) {
                errors.push('birthdate');
            }
        }

        // if (this.state.response.permissions.edit_email) {
        //     if ((this.state.email === undefined && !this.state.response.user.email) || (this.state.email !== undefined && this.state.email.length === 0)) {
        //         errors.push('email');
        //     }
        // }

        if (this.state.response.permissions.edit_address) {
            if ((this.state.address === undefined && !this.state.response.user.address) || (this.state.address !== undefined && this.state.address.length === 0)) {
                errors.push('address');
            }
            if ((this.state.postalCode === undefined && !this.state.response.user.postalCode) || (this.state.postalCode !== undefined && this.state.postalCode.length === 0)) {
                errors.push('postalCode');
            }
            if ((this.state.postalName === undefined && !this.state.response.user.postalName) || (this.state.postalName !== undefined && this.state.postalName.length === 0)) {
                errors.push('postalName');
            }
        }

        if (!this.state.accepted) {
            errors.push('accepted');
        }

        this.setState({errors});
        return errors;
    }
}