import * as React from "react";
import JsonApi from "../../common/JsonApi";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "material-ui";
import ShoppingCart from "./ShoppingCart";
import CustomFieldsEditor from "../../common/CustomFieldsEditor";
import { THEME } from "../../common/Constants";

export default class ActivityApplyAction extends React.Component {
    state = {modal: null};

    render() {
        const activity = this.props.activity;

        if (this.state.modal)
            return <div><ApplyButton disabled label={'Arbeider'}/>{this.state.modal}</div>;

        if (activity.bookingDeadlineExpired && (!this.props.isAdmin || activity.ended))
            return <ApplyDot disabled/>;

        if (ShoppingCart.isActivityInCart(activity._id)) {
            return <ApplyButton
                label={'Lagt til'}
                disabled
            />;
        }
        if (ShoppingCart.isEnrolledToActivity(activity._id)) {
            return <ApplyButton
                label={'Meldt på'}
                disabled
            />;
        }

        if (this.props.child && (!ShoppingCart.data || !ShoppingCart.data.bookingDenied)) {
            if (this.props.enrollmentGroup.isViewing) {
                return <ApplyDot
                    disabled
                />;
            } else if (this.props.enrollmentGroup.isEnrollment) {
                return <ApplyButton
                    label={'Legg til'}
                    onClick={() => {
                        this.setState({
                            modal: <AddToCartModal {...this.props} onRequestClose={() => {
                                this.setState({modal: null});
                            }}/>
                        });
                    }}
                />;
            } else if (this.props.enrollmentGroup.isDirectBooking) {
                return <ApplyButton
                    label={'Meld på'}
                    text={this.getText()}
                    red={this.isWaitingList()}
                    disabled={this.isFull()}
                    onClick={() => {
                        this.setState({
                            modal: <AddToCartModal {...this.props} onRequestClose={() => {
                                this.setState({modal: null});
                            }}/>
                        });
                    }}
                />;
            } else {
                return <div/>;
            }
        } else {

            if (this.props.enrollmentGroup.isViewing) {
                return <ApplyDot
                    disabled
                />;
            } else if (this.props.enrollmentGroup.isEnrollment) {
                return <ApplyDot/>;
            } else if (this.props.enrollmentGroup.isDirectBooking) {
                return <ApplyDot
                    text={this.getText()}
                />;
            } else {
                return <div/>;
            }
        }
    }

    isFull() {
        const activity = this.props.activity;
        return activity.numberOfVacantSlots === 0 && activity.numberOfParticipantsInWaitingList === null;
    }

    getText() {
        const activity = this.props.activity;

        let text = null;

        if (activity.numberOfVacantSlots === null) {

        } else if (activity.numberOfVacantSlots > 0) {
            if (activity.hideNumberOfAvailableSlots)
                text = 'Har ledige plasser';
            else
                text = activity.numberOfVacantSlots + ' ledige plasser';
        } else if (activity.numberOfParticipantsInWaitingList !== null) {
            if (activity.hideNumberOfAvailableSlots)
                text = 'Venteliste aktiv';
            else
                text = activity.numberOfParticipantsInWaitingList + ' på venteliste';
        } else {
            text = 'Fullt kurs';
        }

        return text;
    }

    isWaitingList() {
        const activity = this.props.activity;
        return activity.numberOfParticipantsInWaitingList !== null;
    }
}

class ApplyButton extends React.Component {
    render() {
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '30px'}}>
            <div style={{textAlign: 'center'}}>
                {this.props.text ? <div
                    style={{fontSize: '13px', color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#9c9c9d', paddingBottom: '8px', textAlign: 'center'}}
                >{this.props.text}</div> : null}
                <a
                    onClick={!this.props.disabled ? this.props.onClick : null}
                    onKeyUp={(event) => enterKeyHandle(event)}
                    tabIndex={this.props.disabled ? -1 : 0}
                    style={{
                        display: 'inline-block',
                        fontSize: '15px',
                        fontWeight: '600',
                        backgroundColor: THEME.USE_V2
                            ? (this.props.disabled ? THEME.BUTTON_BACKGROUND_DISABLE : (this.props.red ? THEME.BUTTON_BACKGROUND_DANGER : THEME.BUTTON_BACKGROUND_WARNING))
                            : (this.props.disabled ? '#DDD' : (this.props.red ? '#e55858' : '#ffd500')),
                        color: THEME.USE_V2
                            ? (this.props.red ? THEME.TEXT_COLOR_WHITE: THEME.TEXT_COLOR)
                            : (this.props.red ? '#fff' : '#3d3d3f'),
                        borderRadius: '30px',
                        padding: '5px 15px',
                        cursor: this.props.disabled ? 'auto' : 'pointer'
                    }}
                >
                    {this.props.label}
                </a>
            </div>
        </div>
    }
}

class ApplyDot extends React.Component {
    render() {
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '30px'}}>
            <div>
                {this.props.text ? <div
                    style={{fontSize: '13px', color: THEME.USE_V2 ? THEME.TEXT_COLOR : '#9c9c9d', paddingBottom: '8px', textAlign: 'center'}}
                >{this.props.text}</div> : null}
                <div style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '10px',
                    background: this.props.disabled ? '#c2c4c4' : '#57585a',
                    margin: '0 auto'
                }}/>
            </div>
        </div>;
    }
}

class AddToCartModal extends React.Component {

    state = {response: null};

    customFieldsEditor: CustomFieldsEditor;

    render() {
        // Fyll inn spørsmål vindu
        // Den har blitt lagt til i ønskelisten
        // Bekreft vindu
        // Feil: Overlapper, Allerede påmeldt, Alder, Øvrig.


        const activity = this.props.activity;
        const child = this.props.child;
        const enrollmentGroup = this.props.enrollmentGroup;

        if (this.state.response === null)
            return <div/>;

        const response = this.state.response;
        if (response.success) {
            ShoppingCart.updateData(child.id, enrollmentGroup._id);
            this.props.onRequestClose();
            return <div/>;
        }
        if (response.error) {
            switch (response.error) {
                default:
                    return <Dialog open onClose={this.props.onRequestClose}>
                        {/*<DialogTitle>Feil</DialogTitle>*/}
                        <DialogContent>
                            <DialogContentText>
                                {response.error}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.props.onRequestClose();
                            }}>Lukk</Button>
                        </DialogActions>
                    </Dialog>
            }
        }
        if (response.step) {
            switch (response.step) {
                case 'confirm-add-to-booking':
                    return <Dialog open onClose={this.props.onRequestClose}>
                        <DialogTitle>Legg til</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Kurset <i>{response.activity.name}</i> vil legges til i ønskelisten
                                til <i>{response.child.name}</i>. Trykk bekreft nedenfor for å gjøre endringen i
                                påmeldingen.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.props.onRequestClose();
                            }}>Avbryt</Button>
                            <Button onClick={() => {

                                JsonApi.post('/api/course-catalog/add-activity', {
                                    enrollmentGroupId: enrollmentGroup._id,
                                    childId: child.id,
                                    activityId: activity._id,
                                    confirm: true,
                                }, async (response) => {
                                    this.setState({response});
                                });

                            }} color={'primary'}>Bekreft</Button>
                        </DialogActions>
                    </Dialog>;
                case 'custom-fields':

                    const onSubmit = () => {

                        JsonApi.post('/api/course-catalog/add-activity', {
                            enrollmentGroupId: enrollmentGroup._id,
                            childId: child.id,
                            activityId: activity._id,
                            confirm: true,
                            customFields: {form: this.customFieldsEditor.getSerializedData()}
                        }, async (response) => {
                            this.setState({response});
                        });
                    };

                    return <Dialog open onClose={this.props.onRequestClose}>
                        <DialogTitle>Fyll inn spørsmål</DialogTitle>
                        <DialogContent>
                            <CustomFieldsEditor
                                data={response.customFieldsData}
                                ref={ref => {
                                    this.customFieldsEditor = ref;
                                    if (ref && this.state.passed !== ref.isValidationPassed())
                                        this.setState({passed: ref.isValidationPassed()});
                                }}
                                onValidationUpdated={() => {
                                    console.log("HEI");
                                    this.setState({passed: this.customFieldsEditor.isValidationPassed()});
                                }}
                                onSubmit={() => onSubmit()}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => {
                                this.props.onRequestClose();
                            }}>Avbryt</Button>
                            <Button onClick={() => onSubmit()} color={'primary'}
                                    disabled={!this.state.passed}>Bekreft</Button>
                        </DialogActions>
                    </Dialog>;
            }
        }
    }

    componentDidMount() {
        const activity = this.props.activity;
        const child = this.props.child;
        const enrollmentGroup = this.props.enrollmentGroup;

        JsonApi.post('/api/course-catalog/add-activity', {
            enrollmentGroupId: enrollmentGroup._id,
            childId: child.id,
            activityId: activity._id,
        }, async (response) => {
            this.setState({response});
        });
    }
}