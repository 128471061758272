import * as React from "react";
import styled from 'styled-components';
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Icon
} from "material-ui";
import JsonApi from "../../common/JsonApi";
import {LocationName} from "../course-catalog/MatrixCourse";
import SummerSchoolThemeProvider from "../../common/SummerSchoolThemeProvider";
import {BookingProcessEscapeMessage} from "../course-catalog/ShoppingCart";
import { COLORS, THEME } from "../../common/Constants";

export default class CheckoutPage extends React.Component {
    state = {response: null, errors: []};

    render() {
        if (this.state.response === null) {
            return <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '400px'
                }}>
                <CircularProgress size={40} color={THEME.USE_V2 ? 'primary': 'accent'}/>
            </div>;
        }

        return <SummerSchoolThemeProvider>
            <div>
                <BookingProcessEscapeMessage child={this.props.child} enrollmentGroup={this.props.enrollmentGroup}
                                             force/>
                <Instructions {...this.props} {...this.state}/>

                {this.state.response.lines.map((line, index) => {
                    return <ActivityLine
                        key={index}
                        line={line}
                        updateData={(callback) => this.updateData(callback)}
                        {...this.props} {...this.state}
                    />;
                })}

                <SelectAdministratorIds
                    error={this.state.errors.indexOf('bookingOwners') !== -1}
                    helperText={this.state.errors.indexOf('bookingOwners') !== -1 ? 'Påkrevd' : null}
                    bookingOwners={this.state.response.bookingOwners}
                    onBookingOwnerIdsUpdated={(ids) => {
                        this.setState({bookingOwnerIds: ids});
                    }}
                />

                <div style={{display: 'flex', alignItems: 'center', paddingTop: '30px'}}>
                    <div>
                        <Button
                            href={'/child/' + this.props.child.id + '/order/' + this.props.enrollmentGroup._id + '/' + (this.state.response.hasQuestions ? 'questions' : '')}
                            raised
                            className="back-button"
                        >
                            <div className="back-button-content">
                                <div className="back-button-icon">
                                    <i className={'fa fa-arrow-circle-left'}/>
                                </div>
                                <div className="back-button-label">Gå tilbake</div>
                            </div>
                        </Button>
                    </div>
                    <div style={{flex: '1'}}/>
                    <div>
                        <SubmitButton {...this.state} {...this.props} setErrors={(errors) => {
                            this.setState({errors});
                        }}/>
                    </div>
                </div>
            </div>
        </SummerSchoolThemeProvider>;
    }

    componentDidMount() {
        this.updateData();
    }

    updateData(callback) {
        JsonApi.post('/api/checkout/get-checkout-data', {
            enrollmentGroupId: this.props.enrollmentGroup._id,
            childId: this.props.child.id,
        }, (response) => {

            if (response.lines.length === 0) {
                location.href = '/?childId=' + this.props.child.id;
                return;
            }

            this.setState({response});
            if (callback)
                callback(response);
        });
    }
}

class SubmitButton extends React.Component {
    state = {loading: false, modal: null};

    render() {
        return <div>
            <Button
                raised
                color={'primary'}
                style={{width: '250px', height: '50px', fontSize: '14px'}}
                onClick={() => {
                    this.submit();
                }}
            >
                {this.state.loading ? <CircularProgress size={25} style={{color: '#FFF'}}/> : 'Bekreft påmelding'}
            </Button>
            {this.state.modal}
        </div>;
    }

    submit() {
        if (this.updateValidation().length > 0)
            return;

        if (this.state.loading)
            return;

        this.setState({loading: true});

        JsonApi.post('/api/checkout/place-booking', {
            enrollmentGroupId: this.props.enrollmentGroup._id,
            childId: this.props.child.id,
            bookingOwnerIds: this.props.bookingOwnerIds
        }, (response) => {
            if (response.success) {
                this.setState({
                    loading: false,
                    modal: <Dialog open onClose={() => {
                        location.href = '/?childId=' + this.props.child.id;
                    }}>
                        <DialogTitle>Påmelding bekreftet.</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <p>Du blir nå sendt til elevsiden.</p>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={() => {
                                location.href = '/?childId=' + this.props.child.id;
                            }}>Lukk</Button>
                        </DialogActions>
                    </Dialog>
                });
            } else if (response.errors) {
                this.setState({
                    loading: false,
                    modal: <Dialog open onClose={() => this.setState({modal: null})}>
                        <DialogTitle>Feilmelding</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <ul>
                                    {response.errors.map(error => {
                                        return <li>{error}</li>;
                                    })}
                                </ul>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={() => {
                                this.setState({modal: null});
                            }}>Lukk</Button>
                        </DialogActions>
                    </Dialog>
                });
            }
            console.log(response);
        })
    }

    updateValidation() {
        const errors = [];

        if (this.props.response.bookingOwners && (!this.props.bookingOwnerIds || this.props.bookingOwnerIds.length < 2)) {
            errors.push('bookingOwners');
        }

        this.props.setErrors(errors);
        return errors;
    }
}

class Instructions extends React.Component {
    render() {
        if (!this.props.response || !this.props.response.description)
            return <div/>;

        return <div style={{marginBottom: '20px', fontSize: '15px'}}>
            <div className={'content'} dangerouslySetInnerHTML={{__html: this.props.response.description}}/>
        </div>
    }
}

class ActivityLine extends React.Component {
    render() {
        const line = this.props.line;

        const Div = styled.div`
            display:flex;
            flex:1;
            align-items:center;
            font-size:15px;
            
            > div, h3 {
                padding: 10px 20px;
            }
        `;

        return <div style={{
            display: 'flex',
            width: '100%',
            border: '1px solid #a4a4a4',
            alignItems: 'stretch',
            marginBottom: '13px'
        }}>
            {line.priority ? <Priority line={line} {...this.props} /> : null}
            <Div>
                <h3 style={{flex: '9', fontWeight: '600'}}>{line.activity.name}</h3>
                <div style={{flex: '4'}}>
                    <div>{line.activity.dateFormatted}</div>
                    <div><LocationName location={line.location}/></div>
                </div>
            </Div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '80px',
                    background: THEME.USE_V2 ? THEME.BUTTON_BACKGROUND_DISABLE : '#cecece',
                    width: '70px',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    JsonApi.post('/api/shopping-cart/remove-from-cart', {
                        childId: this.props.child.id,
                        enrollmentGroupId: this.props.enrollmentGroup._id,
                        activityId: this.props.line.activity._id
                    }, (res) => {
                        this.props.updateData(response => {
                            this.setState({loading: false});
                        });
                    });
                }}
                onKeyUp={(event) => window.enterKeyHandle(event)}
                tabIndex={0}
            >
                <img src={'/img/icons/close.png'} alt="Lukk ikon" style={{height: '30px'}}/>
            </div>
        </div>;
    }
}

class Priority extends React.Component {
    state = {loading: false};

    render() {

        const canShiftUp = this.props.line.priority !== 1;
        const canShiftDown = this.props.line.priority !== this.props.response.lines.length;

        if (this.state.loading) {
            return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70px'}}>
                <CircularProgress color={'accent'} size={35}/>
            </div>;
        }
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70px'}}>
            <div>
                <div
                    onClick={() => {
                        if (!canShiftUp)
                            return;

                        this.setState({loading: true});

                        JsonApi.post('/api/shopping-cart/move-up', {
                            childId: this.props.child.id,
                            enrollmentGroupId: this.props.enrollmentGroup._id,
                            activityId: this.props.line.activity._id
                        }, (res) => {
                            this.props.updateData(response => {
                                this.setState({loading: false});
                            });
                        });
                    }}
                    style={{margin: '0 auto', textAlign: 'center', cursor: canShiftUp ? 'pointer' : 'inherit'}}
                    tabIndex={canShiftUp ? 0 : -1}
                    onKeyUp={(event) => enterKeyHandle(event)}
                >
                    <Icon style={{
                        color: canShiftUp ? '#57585a' : 'rgba(87,88,90,0.3)',
                        fontSize: '35px',
                        lineHeight: '22px'
                    }}>arrow_drop_up</Icon>
                </div>
                <div style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '15px',
                    backgroundColor: THEME.USE_V2 ? COLORS.YELLOW : '#f5b290',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '15px',
                    fontWeight: '600',
                    flexFlow: 'column',
                    margin: '0 auto'
                }}>{this.props.line.priority}</div>
                <div
                    onClick={() => {
                        if (!canShiftDown)
                            return;

                        this.setState({loading: true});

                        JsonApi.post('/api/shopping-cart/move-down', {
                            childId: this.props.child.id,
                            enrollmentGroupId: this.props.enrollmentGroup._id,
                            activityId: this.props.line.activity._id
                        }, (res) => {
                            this.props.updateData(response => {
                                this.setState({loading: false});
                            });
                        });
                    }}
                    style={{margin: '0 auto', textAlign: 'center', cursor: canShiftDown ? 'pointer' : 'inherit'}}
                    tabIndex={canShiftDown ? 0 : -1}
                    onKeyUp={(event) => enterKeyHandle(event)}
                >
                    <Icon style={{
                        color: canShiftDown ? '#57585a' : 'rgba(87,88,90,0.3)',
                        fontSize: '35px',
                        lineHeight: '22px'
                    }}>arrow_drop_down</Icon>
                </div>
            </div>
        </div>
    }
}

class SelectAdministratorIds extends React.Component {
    state = {bookingOwnerIds: null};

    render() {

        if (!this.props.bookingOwners)
            return <div/>;

        return <div>
            <h3 style={{fontSize: '20px', fontWeight: '600', marginTop: '30px', marginBottom: '0'}}>Hvilken foresatt
                skal motta informasjon og kunne endre påmeldingen?</h3>

            <FormControl error={this.props.error}>
                <FormGroup row>
                    {this.props.bookingOwners.map(bookingOwner => {
                        return <FormControlLabel
                            key={bookingOwner.id}
                            disabled={bookingOwner.required}
                            control={
                                <Checkbox
                                    checked={this.state.bookingOwnerIds.indexOf(bookingOwner.id) !== -1}
                                    onChange={() => {
                                        if (this.state.bookingOwnerIds.indexOf(bookingOwner.id) !== -1)
                                            this.state.bookingOwnerIds.splice(this.state.bookingOwnerIds.indexOf(bookingOwner.id), 1);
                                        else
                                            this.state.bookingOwnerIds.push(bookingOwner.id);

                                        this.setState({bookingOwnerIds: this.state.bookingOwnerIds});
                                        this.props.onBookingOwnerIdsUpdated(this.state.bookingOwnerIds);
                                    }}
                                />
                            }
                            label={bookingOwner.name}
                        />
                    })}
                </FormGroup>
                {this.props.helperText ? <FormHelperText>{this.props.helperText}</FormHelperText> : null}
            </FormControl>
        </div>;
    }

    componentWillMount() {
        if (this.props.bookingOwners) {
            let bookingOwnerIds = [];
            this.props.bookingOwners.forEach(bookingOwner => {
                if (bookingOwner.required)
                    bookingOwnerIds.push(bookingOwner.id);
            });
            this.setState({bookingOwnerIds});
        }
    }
}