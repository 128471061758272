import * as React from 'react';
import CustomFieldsEditor from "../../common/CustomFieldsEditor";
import {Button} from "material-ui";
import JsonApi from "../../common/JsonApi";
import SummerSchoolThemeProvider from "../../common/SummerSchoolThemeProvider";
import {BookingProcessEscapeMessage} from "../course-catalog/ShoppingCart";

export default class QuestionsPage extends React.Component {
    state = {passed: false};

    customFieldsEditor: CustomFieldsEditor;

    render() {
        return <SummerSchoolThemeProvider>
            <div>
                <BookingProcessEscapeMessage child={this.props.child} enrollmentGroup={this.props.enrollmentGroup} force />
                <CustomFieldsEditor
                    data={this.props.customFieldsData}
                    ref={ref => {
                        if (ref) {
                            this.customFieldsEditor = ref;
                            if (this.state.passed !== this.customFieldsEditor.isValidationPassed())
                                this.setState({passed: this.customFieldsEditor.isValidationPassed()});
                        }
                    }}
                    onSubmit={() => {
                        this.submit(() => {
                            if (this.customFieldsEditor.isValidationPassed())
                                location.href = '/child/' + this.props.child.id + '/order/' + this.props.enrollmentGroup._id + '/checkout';
                        });
                    }}
                    onValidationUpdated={() => {
                        this.setState({passed: this.customFieldsEditor.isValidationPassed()});
                    }}
                />

                <div style={{display: 'flex', alignItems: 'center', paddingTop: '30px'}}>
                    <div>
                        <Button
                            onClick={() => {
                                this.submit(() => {
                                    location.href = '/child/' + this.props.child.id + '/order/' + this.props.enrollmentGroup._id;
                                });
                            }}
                            raised
                            className="back-button"
                        >
                            <div className="back-button-content">
                                <div className="back-button-icon">
                                    <i className={'fa fa-arrow-circle-left'}/>
                                </div>
                                <div className="back-button-label">Gå tilbake</div>
                            </div>
                        </Button>
                    </div>
                    <div style={{flex: '1'}}/>
                    <div>
                        <Button raised color={'primary'} style={{width: '250px', height: '50px', fontSize: '14px'}}
                                disabled={!this.state.passed} onClick={() => this.submit(() => {
                            if (this.customFieldsEditor.isValidationPassed())
                                location.href = '/child/' + this.props.child.id + '/order/' + this.props.enrollmentGroup._id + '/checkout';
                        })}>
                            Gå videre
                        </Button>
                    </div>
                </div>
            </div>
        </SummerSchoolThemeProvider>;
    }

    submit(callback) {
        JsonApi.post('/api/checkout/save-questions', {
            enrollmentGroupId: this.props.enrollmentGroup._id,
            childId: this.props.child.id,
            customFields: {bookingBundle: this.customFieldsEditor.getSerializedData()}
        }, (response) => {
            callback();
        });
    }
}