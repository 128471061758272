import * as React from "react";
import styled from 'styled-components';
import ActivityApplyAction from "./ActivityApplyAction";
import CourseCatalogFilters from "./CourseCatalogFilters";
import {LocationMatrixButton, LocationName} from "./MatrixCourse";
import ContentFormatted from "../../common/ContentFormatted";

export default class MobileMatrixCourse extends React.Component {
    render() {
        const catalog = this.props.catalog;
        const course = this.props.course;


        const Th = styled.th`
            padding: 10px 0;
            font-size:15px;
            border:none !important;
            text-transform:none;
            font-size:15px;
            
            &:nth-child(n+2){
                text-align: center;
            }
        `;
        const Td = styled.td`
            padding: 10px;
            font-size:15px;
            vertical-align: middle;
            border:none !important;
            border-top: 1px solid #e6e6e6 !important;
            font-size:15px;
        `;

        return <div style={{fontSize: '15px'}}>
            <div style={{padding: '20px 30px'}}>
                <ContentFormatted html={course.description}/>
                <LocationMatrixButton {...this.props} />
            </div>
            <div style={{padding: '10px 20px', fontWeight: '600'}}>
                Tilgjengelige kurssteder
            </div>

            {Object.keys(course.matrix).map(locationId => {
                const location = catalog.locations[locationId];

                let show = false;

                const locationHtml = <div key={locationId}
                                          style={{padding: '13px 24px', borderTop: '1px solid #e6e6e6'}}>
                    <div><LocationName location={location} {...this.props}/></div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {Object.keys(course.weeks).map(weekId => {
                            if (!this.getFilters().isWeekShown(weekId))
                                return null;

                            if (!course.matrix[locationId][weekId] || Object.keys(course.matrix[locationId][weekId]).length === 0)
                                return null;

                            show = true;
                            const week = course.weeks[weekId];
                            return <div key={weekId}
                                        style={{width: '140px', padding: '5px'}}>
                                <div style={{
                                    fontWeight: '600',
                                    padding: '5px',
                                    textAlign: 'center'
                                }}>Uke {week.week}</div>
                                {course.matrix[locationId][weekId]
                                    ? Object.keys(course.matrix[locationId][weekId]).map(activityId => {
                                        const activity = course.matrix[locationId][weekId][activityId];
                                        return <div
                                            style={{padding: '5px', display: 'flex', justifyContent: 'center'}}>
                                            <ActivityApplyAction
                                                key={activityId}
                                                activity={activity}
                                                {...this.props}
                                            />
                                        </div>;
                                    })
                                    : null}
                            </div>;
                        })}
                    </div>
                </div>;

                return show ? locationHtml : null;
            })}
        </div>;
    }

    getFilters(): CourseCatalogFilters {
        return this.props.catalogFilters;
    }
}